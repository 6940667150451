import * as Choices from 'choices.js'
import Manipulator from 'lib/dom/manipulator'
import { debounce } from 'lib/debounce'

// Taken from https://gist.github.com/leastbad/e6773a0c800e96ba7c76e342e4e40ef7
export const useSelect = (controller) => {
  Object.assign(controller, {
    initializeChoices() {
      this.optionsReducer = this.optionsReducer.bind(controller)
      this.searchPath = this.selectContainerTarget.dataset.searchPath
      this.customActionTemplate = this.selectContainerTarget.dataset.customActionTemplate
      this.forceOption = this.selectContainerTarget.dataset.forceOption || true
    },

    connectChoices() {
      if (this.choices) return

      if (this.hasSelectTarget) this.setup()
    },

    disconnectChoices() {
      if (this.searchPath) this.input?.removeEventListener('input', this.search)

      if (this.hasSelectTarget) {
        this.selectTarget.removeEventListener('change', this.refresh)
        this.selectTarget.removeEventListener('addItem', this.add)
        this.selectTarget.removeEventListener('removeItem', this.remove)
      }

      try {
        this.choices.destroy()
      } catch (e) {}

      this.choices = undefined
    },

    setup: function() {
      this.choices = new Choices(this.selectTarget, {
        ...this.options()
      })
      this.input = this.element.querySelector('input.choices__input')
      this.refresh()

      if (this.searchPath) this.input.addEventListener('input', debounce(this.search, 400))

      this.selectTarget.addEventListener('change', this.refresh)
      this.selectTarget.addEventListener('addItem', this.add)
      this.selectTarget.addEventListener('removeItem', this.remove)
    }.bind(controller),

    refresh: function() {
      this.choices.setChoices([], 'value', 'label', false)
      if (this.hasOptionsTarget) {
        this.optionsTarget.children.forEach(this.append.bind(this))
      }
    }.bind(controller),

    append: function(option) {
      // if option was already added
      if (
        [...this.selectTarget.options].some((o) => {
          return o.label === option.label
        })
      ) {
        return
      }

      this.choices.setChoices([option], 'value', 'label', false)
    }.bind(controller),

    add: function(event) {
      if (this.hasSelectTarget) {
        const option = [...this.selectTarget.children].find((option) => {
          return option.label === event.detail.label
        })

        if (option) {
          option.setAttribute('selected', '')
        } else {
          const newOption = document.createElement('option')

          newOption.setAttribute('label', event.detail.label)
          newOption.setAttribute('value', event.detail.value)
          newOption.setAttribute('selected', '')

          this.selectTarget.appendChild(newOption)
        }
      }
    }.bind(controller),

    remove: function(event) {
      if (this.hasOptionsTarget) {
        const option = [...this.optionsTarget.children].find((item) => {
          return item.label === event.detail.label
        })

        if (option) this.searchPath ? option.remove() : option.removeAttribute('selected')
      }
      if (this.forceOption && !this.selectTarget.options.length)
        this.selectTarget.add(document.createElement('option'))
    }.bind(controller),

    search: function(event) {
      const q = encodeURIComponent(event.target.value)

      if (q !== undefined) {
        let concatenator = this.searchPath.includes("?") ? "&" : "?"
        fetch(`${this.searchPath}${concatenator}q=${q}`, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
          .then((response) => response.json())
          .then(this.update)
      } else {
        this.refresh()
      }
    }.bind(controller),

    update: function(data) {
      const options = data.filter(this.filter).map((o) => ({ value: o[0], label: o[1] }))

      this.choices.setChoices(options, 'value', 'label', true)
    }.bind(controller),

    filter: function(item) {
      return ![...this.selectTarget.options].some((option) => {
        return option.label === item.label
      })
    }.bind(controller),

    options() {
      return [
        'silent',
        'renderChoiceLimit',
        'maxItemCount',
        'addItems',
        'removeItems',
        'removeItemButton',
        'editItems',
        'duplicateItemsAllowed',
        'delimiter',
        'paste',
        'searchEnabled',
        'searchChoices',
        'searchFloor',
        'searchResultLimit',
        'position',
        'resetScrollPosition',
        'addItemFilter',
        'shouldSort',
        'shouldSortItems',
        'placeholder',
        'placeholderValue',
        'prependValue',
        'appendValue',
        'renderSelectedChoices',
        'loadingText',
        'noResultsText',
        'noChoicesText',
        'itemSelectText',
        'addItemText',
        'maxItemText',
        'allowHTML'
      ].reduce(this.optionsReducer, {
        classNames: this.classNames(),
        loadingText: 'Loading...',
        searchResultLimit: 100,
        allowHTML: false,
        callbackOnInit: function() {
          if (controller.selectTarget.autofocus) {
            controller.element.querySelector('button.choices__button').click()
            controller.element.querySelector('input.choices__input').focus()
          }
        },
        callbackOnCreateTemplates: this.customActionTemplate
          ? function(template) {
              return {
                dropdown: ({ classNames }, _data) => {
                  return template(controller.customeExternalActionTemplate(classNames))
                }
              }
            }.bind(controller)
          : undefined
      })
    },

    optionsReducer(accumulator, currentValue) {
      if (this.selectContainerTarget.dataset[currentValue]) {
        accumulator[currentValue] = Manipulator.getDataAttribute(
          this.selectContainerTarget,
          currentValue
        )
      }

      return accumulator
    },

    customeExternalActionTemplate(classNames) {
      return `<div class="${classNames.list} ${classNames.listDropdown}"aria-expanded="false">
                <div class="${classNames.itemChoice} ${classNames.item} pointer-cursor"> ${this.customActionTemplate} </div>
              </div>`
    },

    classNames() {
      let borderColor = 'border-gray-300'
      if (this.selectContainerTarget.querySelector('span.text-danger-text')) {
        borderColor = 'is-invalid border-danger-text'
      }
      return {
        containerInner: `choices__inner bg-white rounded ${borderColor}`,
        input: 'choices__input',
        item: 'choices__item break-normal'
      }
    }
  })
}
