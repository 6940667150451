export const useLockScroll = (controller) => {
  return Object.assign(controller, {
    lockScroll() {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
      document.body.style.paddingRight = `${scrollbarWidth}px`
      this.saveScrollPosition()
      document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')
      document.body.style.top = `-${this.scrollPosition}px`
    },

    unlockScroll() {
      document.body.style.paddingRight = null
      document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')
      this.restoreScrollPosition()
      document.body.style.top = null
    },

    saveScrollPosition() {
      this.scrollPosition = window.pageYOffset || document.body.scrollTop
    },

    restoreScrollPosition() {
      document.documentElement.scrollTop = this.scrollPosition
    }
  })
}
