import ApplicationController from './application_controller'
import { useSelect } from './mixins/useSelect'

export default class extends ApplicationController {
  static targets = ['showContainer', 'editContainer', 'select', 'options', 'selectContainer', 'spinner']

  initialize() {
    super.initialize()
    this.mode = 'show'
  }

  connect() {
    useSelect(this)
    super.connect()

    if (!this.choices) {
      this.initializeChoices()
      this.connectChoices()
    }

    if (this.hasSelectTarget) this.selectTarget.addEventListener('change', this.handleChange.bind(this))
  }

  disconnect() {
    if (this.hasSelectTarget) {
      this.disconnectChoices()
      this.selectTarget.removeEventListener('change', this.handleChange)
    }
  }

  beforeReflex() {
    this._showSpinner()
  }

  afterReflex() {
    this._hideSpinner()
  }

  reflexSuccess() {
    this.showMode()
  }

  handleChange(event) {
    if (!event.currentTarget.value) return
    this.stimulate(this.element.dataset.reflexAction, event.currentTarget)
  }

  handleClickOutside(event) {
    const controller = event.target.closest(`[data-controller="${this.element.dataset.controller}"]`)
    if (this.element === controller) {
      return
    }

    if (this.mode === 'edit') this.showMode()
  }

  showMode() {
    this.mode = 'show'
    this.choices?.clearInput()
    this.editContainerTarget.classList.add('hidden')
    this.showContainerTarget.classList.remove('hidden')
  }

  editMode() {
    this.mode = 'edit'
    this.showContainerTarget.classList.add('hidden')
    this.editContainerTarget.classList.remove('hidden')
    this.choices.showDropdown()
  }

  _hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
  }

  _showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
  }
}
