import ApplicationController from './application_controller'
import { useSpinner } from './mixins/useSpinner'

const DISABLED_HTML_EL = Object.freeze({
  INPUT: 'INPUT',
  BUTTON: 'BUTTON',
  COMMAND: 'COMMAND',
  FIELDSET: 'FIELDSET',
  OPTION: 'OPTION',
  SELECT: 'SELECT',
  TEXTAREA: 'TEXTAREA',
  KEYGEN: 'KEYGEN',
  OPTGROUP: 'OPTGROUP'
})

const WAIT_DATA_ATTR = 'data-wait-for-reflex="true"'

export default class extends ApplicationController {
  static targets = ['spin']

  connect() {
    super.connect()
    useSpinner(this)

    this.stimulusReflexPreviouslyConnected = false

    this._disabledReflexElements()
    this._showGlobalSpinner()

    document.addEventListener('stimulus-reflex:before', this.handlebefore)
    document.addEventListener('stimulus-reflex:halted', this.handleHalted)
    document.addEventListener('stimulus-reflex:disconnected', this.handleStimulusReflexDisconnected)
    document.addEventListener('stimulus-reflex:ready', this.handleStimulusReflexReady)
  }

  disconnect() {
    document.removeEventListener('stimulus-reflex:before', this.handlebefore)
    document.removeEventListener('stimulus-reflex:halted', this.handleHalted)
    document.removeEventListener('stimulus-reflex:ready', this.handleStimulusReflexReady)
    document.removeEventListener(
      'stimulus-reflex:disconnected',
      this.handleStimulusReflexDisconnected
    )

    this.tearDownSpinner()
  }

  handlebefore = () => {
    this._showGlobalSpinner()
  }

  handleHalted = () => {
    this._hideGlobalSpinner()
  }

  handleStimulusReflexReady = () => {
    if (!this.stimulusReflexPreviouslyConnected) {
      this._enabledReflexElements()
    }
    this._hideGlobalSpinner()
    this.stimulusReflexPreviouslyConnected = true
  }

  handleStimulusReflexDisconnected = () => {
    if (this.stimulusReflexPreviouslyConnected) {
      this._disabledReflexElements()
    }
    this.stimulusReflexPreviouslyConnected = false
  }

  _disabledReflexElements = () => {
    document.querySelectorAll(`[${WAIT_DATA_ATTR}]`).forEach((el) => {
      this._disabledElement(el)
    })
  }

  _enabledReflexElements = () => {
    document.querySelectorAll(`[${WAIT_DATA_ATTR}]`).forEach((el) => {
      this._enabledElement(el)
    })
  }

  _disabledElement(element) {
    if (this._isDisabledElement(element)) element.disabled = true

    if (element.children?.length)
      this._disabledChildren(element).forEach((el) => (el.disabled = true))

    element.classList.add('disabled')
  }

  _enabledElement(element) {
    if (this._isDisabledElement(element)) element.disabled = false

    if (element.children?.length)
      this._disabledChildren(element).forEach((el) => (el.disabled = false))

    element.classList.remove('disabled')
  }

  _isDisabledElement(element) {
    return !!Object.keys(DISABLED_HTML_EL).find(
      (disabledTagName) => disabledTagName === element.tagName
    )
  }

  _disabledChildren(element) {
    let disabledChildren = []
    let children = [].concat(...element.children)

    Object.keys(DISABLED_HTML_EL).forEach((tagName) => {
      disabledChildren = [...disabledChildren, ...children.filter((child) => child.matches(tagName))]
    })

    return disabledChildren
  }

  _showGlobalSpinner() {
    if (this.spinTarget.classList.contains('hidden')) this.showSpinner()
  }

  _hideGlobalSpinner() {
    if (!this.spinTarget.classList.contains('hidden')) this.hideSpinner()
  }
}
