import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["form", "ignoreDuplicates"]

  ignoreDuplicatesAndSubmit(_event) {
    this.ignoreDuplicatesTarget.value = true
    this.formTarget.requestSubmit()
  }
}
