import DOMEvents from 'lib/dom/events'

export const useMenu = (controller) => {
  return Object.assign(controller, {
    bindEvents() {
      this.element.addEventListener('focus', this.handleMenuFocus.bind(this))
      this.element.addEventListener('blur', this.handleMenuBlur.bind(this))
    },

    tearDownEvents() {
      this.element.removeEventListener('focus', this.handleMenuFocus)
      this.element.removeEventListener('blur', this.handleMenuBlur)
    },

    handleKeyDown(event) {
      if (event.ctrlKey || event.altKey || event.metaKey || !this.menuitemTargets.length) {
        return
      }

      switch (event.keyCode) {
        case DOMEvents.keyCodes.DOWN:
          this._setFocusToNextMenuItem()
          break
        case DOMEvents.keyCodes.UP:
          this._setFocusToPreviousMenuItem()
          break
        case DOMEvents.keyCodes.HOME:
        case DOMEvents.keyCodes.PAGEUP:
          this._setFocusToFirstItem()
          break
        case DOMEvents.keyCodes.END:
        case DOMEvents.keyCodes.PAGEDOWN:
          this._setFocusToLastItem()
          break
        case DOMEvents.keyCodes.TAB:
          this.onTABPress && this.onTABPress()
          break
        case DOMEvents.keyCodes.ESC:
          this.onESCPress && this.onESCPress()
          break
        case DOMEvents.keyCodes.RETURN:
          this.handleMenuItemClick(event)
          break
      }
    },

    handleMenuFocus() {
      this._setFocus(0)
    },

    handleMenuBlur() {
      this.menuitemTargets[this.activeIndex].removeAttribute('data-active')
      this.activeIndex = null
    },

    handleMenuItemClick(event) {
      const aElements = event.currentTarget.querySelectorAll('a')
      if (aElements.length) {
        aElements.forEach((a) => {
          a.click()
        })
      }

      this.onMenuItemClick && this.onMenuItemClick(event)
    },

    _scrollToHighlighted() {
      this.menuitemTargets[this.activeIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    },

    _setFocusToNextMenuItem() {
      if (this._isLastElement()) {
        this._setFocusToFirstItem()
      } else {
        this._setFocus(this.activeIndex === null ? 0 : this.activeIndex + 1)
      }
      this._scrollToHighlighted()
    },

    _setFocusToPreviousMenuItem() {
      if (this._isFirstElement()) {
        this._setFocusToLastItem()
      } else {
        this._setFocus(this.activeIndex - 1)
      }
      this._scrollToHighlighted()
    },

    _setFocusToLastItem() {
      this._setFocus(this.menuitemTargets.length - 1)
    },

    _setFocusToFirstItem() {
      this._setFocus(0)
    },

    _isLastElement() {
      return this.activeIndex >= this.menuitemTargets.length - 1
    },

    _isFirstElement() {
      return this.activeIndex === 0
    },

    _setFocus(newActiveIndex) {
      this.menuitemTargets[this.activeIndex || 0].removeAttribute('data-active')
      this.activeIndex = newActiveIndex
      this.menuitemTargets[this.activeIndex].focus()
      this.menuitemTargets[this.activeIndex].setAttribute('data-active', true)
    },

    _focusOnList() {
      this.menuTarget.focus()
    }
  })
}
