import ApplicationController from './application_controller'
import { useMenu } from './mixins/useMenu'

export default class extends ApplicationController {
  static targets = ['menuitem', 'menu']

  connect() {
    useMenu(this)
    this.activeIndex = null
    this.bindEvents()
  }

  disconnect() {
    this.tearDownEvents()
  }
}
