import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    document.addEventListener('turbo:submit-start', this.handleSubmitStart)
    document.addEventListener('turbo:submit-end', this.handleSubmitEnd)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-start', this.handleSubmitStart)
    document.removeEventListener('turbo:submit-end', this.handleSubmitEnd)
  }

  handleSubmitStart = (e) => {
    const submitButton = this.findSubmitButton(e.target)
    if (!submitButton) return

    submitButton.disabled = true
    if (submitButton.dataset.disableWith) {
      submitButton.setAttribute('data-label', submitButton.innerText)
      submitButton.innerText = submitButton.getAttribute('data-disable-with') || 'Submitting...'
    }
  }

  handleSubmitEnd = (e) => {
    const submitButton = this.findSubmitButton(e.target)
    if (!submitButton) return

    submitButton.disabled = false
    if (submitButton.dataset.disableWith) {
      submitButton.innerText = submitButton.getAttribute('data-label') || 'Submit'
    }
  }

  findSubmitButton = (formTarget) => {
    return (
      formTarget.querySelector('button[type="submit"]') ||
      document.querySelector(`button[type="submit"][form="${formTarget.id}"]`)
    )
  }
}
