export const useSelectSellingBy = (controller) => {
  return Object.assign(controller, {
    onBatchSelectSellingByChange(event) {
      switch (event.target.value) {
        case 'cases':
          this.productQuantityInputTargets.forEach((target) => {
            const select = this._getSelectSellingBy(target)

            if (select) {
              select.value = 'cases'
            }
          })
          break
        case 'units':
        default:
          this.productQuantityInputTargets.forEach((target) => {
            const select = this._getSelectSellingBy(target)

            if (select) {
              select.value = 'units'
            }
          })
          break
      }
    },

    onSelectSellingByChange(event) {
      this._updateBatchSelectSellingBy(event.target.value)
    },

    _getSelectSellingBy(productQuantityInputTarget) {
      return productQuantityInputTarget.getElementsByTagName('select')?.[0]
    },

    _updateBatchSelectSellingBy(value) {
      if (this.sellingBy === value) return

      if (this._haveAllSameValue(value)) {
        this.batchSelectSellingByTarget.value = value
      }
    },

    _haveAllSameValue(value) {
      return !this.productQuantityInputTargets.some((quantityInput) => {
        const select = this._getSelectSellingBy(quantityInput)

        return select && select.value !== value
      })
    }
  })
}
