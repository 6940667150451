export const useActionsBar = (controller) => {
  return Object.assign(controller, {
    beforeReflex(element) {
      this.showClosestSpinner(element)
    },
    reflexError(element) {
      this.hideClosestSpinner(element)
    },
    accept(event) {
      this.showClosestSpinner(event.target)
    },
    place(event) {
      this.showClosestSpinner(event.target)
    },
    prepareToShip(event) {
      this.stimulate('OrderReflex#prepare_to_ship', event.currentTarget)
    },
    cancel(event) {
      if (window.confirm('Are you sure you want to cancel this order?')) {
        this.stimulate('OrderReflex#cancel', event.currentTarget)
      }
    },
    regenerateInvoice(event) {
      this.stimulate('OrderReflex#regenerate_invoice', event.currentTarget)
    },
    regenerateBillOfLading(event) {
      this.stimulate('OrderReflex#regenerate_bill_of_lading', event.currentTarget)
    },
    deliver(event) {
      this.stimulate('OrderReflex#deliver', event.currentTarget)
    },
    integrationDeliver(event) {
      let confirmed = confirm(`By marking this order as delivered, you will be overriding what we received from the integration. Please confirm with your integration that your products have been delivered. Do you wish to proceed?`)
      if (!confirmed) {
        event.preventDefault()
        return
      }
      this.stimulate('OrderReflex#integration_deliver', event.currentTarget)
    },
    post(event) {
      this.stimulate('OrderReflex#post', event.currentTarget)
    },
    unpost(event) {
      this.stimulate('OrderReflex#unpost', event.currentTarget)
    },
    exportToQuickbooks(event) {
      this.stimulate('OrderReflex#export_to_quickbooks', event.currentTarget)
    }
  })
}
