import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];
  static values = {id: String, seconds: {type: Number, default: 1}};

  connect() {
    const hideTimestamp = sessionStorage.getItem(`hideTimestamp-${this.idValue}`);
    if (hideTimestamp) {
      const elapsedTime = Date.now() - parseInt(hideTimestamp, 10);

      if (elapsedTime < this.secondsValue * 1000) {
        this.hide();
      } else {
        sessionStorage.removeItem(`hideTimestamp-${this.idValue}`);
      }
    }
  }

  hide() {
    this.elementTarget.style.display = 'none';

    const hideTimestamp = Date.now();
    sessionStorage.setItem(`hideTimestamp-${this.idValue}`, hideTimestamp);
  }
}
