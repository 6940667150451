import { createPopper } from '@popperjs/core'
import Manipulator from 'lib/dom/manipulator'
import WebAnimation from 'lib/animation'

export const usePopper = (controller) => {
  return Object.assign(controller, {
    _hideOverlay() {
      this.show = false
      Manipulator.setAriaAttribute(this.triggerTarget, 'expanded', false)
      WebAnimation.leave(this.overlayTarget.getElementsByClassName('dropdown__overlay')[0], {
        keyframes: {
          opacity: [1, 0],
          transform: ['scale(1)', 'scale(0.95)']
        },
        options: { duration: 100, easing: 'ease-in', fill: 'forwards' },
        elementToHide: this.overlayTarget,
        onfinish: this._destroyPopper
      })
      this.popperClosed && this.popperClosed()
    },

    _showOverlay() {
      this.show = true
      Manipulator.setAriaAttribute(this.triggerTarget, 'expanded', true)
      this._createPopper()
      WebAnimation.enter(this.overlayTarget.getElementsByClassName('dropdown__overlay')[0], {
        keyframes: {
          opacity: [0, 1],
          transform: ['scale(0.95)', 'scale(1)']
        },
        options: { duration: 100, easing: 'ease-out', fill: 'forwards' },
        elementToShow: this.overlayTarget
      })
      this.popperOpened && this.popperOpened()
    },

    _destroyPopper() {
      if (this?.popper) {
        this.popper.destroy()
        this.popper = null
      }
    },

    _createPopper(options = {}) {
      this.popper = createPopper(this.triggerTarget, this.overlayTarget, {
        placement: this.placement,
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ],
        ...options
      })
    }
  })
}
