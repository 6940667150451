class Session {
  static setSessionStorage(key, value) {
    return sessionStorage.setItem(key, value)
  }

  static getSessionStorage(key) {
    return sessionStorage.getItem(key)
  }
}

export default Session
