import ApplicationController from './application_controller'
import WebAnimation from 'lib/animation'

export default class extends ApplicationController {
  hide(event) {
    event.preventDefault()
    WebAnimation.leave(this.element, {
      keyframes: {
        opacity: [1, 0]
      },
      options: {
        duration: 200,
        easing: 'ease-in'
      }
    })
  }
}
