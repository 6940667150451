import WebAnimation from 'lib/animation'

export const useSpinner = (controller) => {
  return Object.assign(controller, {
    tearDownSpinner(spinTarget) {
      if (!spinTarget && !this.hasSpinTarget) return

      spinTarget = spinTarget || this.spinTarget
      if (!spinTarget.classList.contains('hidden')) this.hideSpinner()
    },

    toggleSpinner(e) {
      if (!this.hasSpinTarget) return

      this.spinTarget.classList.contains('hidden') ? this.showSpinner() : this.hideSpinner()
    },

    hideSpinner(spinTarget = this.spinTarget, containerTarget = this.containerTarget) {
      if (!spinTarget) return

      WebAnimation.leave(spinTarget, {
        keyframes: {
          opacity: [1, 0]
        },
        options: { duration: 300, easing: 'ease-in', fill: 'forwards' }
      })

      if (containerTarget) {
        WebAnimation.leave(containerTarget, {
          keyframes: {
            opacity: [0.6, 0],
            background: ['#fff', 'transparent']
          },
          options: { duration: 300, easing: 'ease-in', fill: 'forwards' }
        })
      }
    },

    showSpinner(spinTarget = this.spinTarget, containerTarget = this.containerTarget) {
      if (!spinTarget) return

      if (containerTarget) {
        WebAnimation.enter(containerTarget, {
          keyframes: {
            opacity: [0, 0.6],
            background: ['transparent', '#fff']
          },
          options: { duration: 400, easing: 'ease-in-out', fill: 'forwards' }
        })
      }

      WebAnimation.enter(spinTarget, {
        keyframes: {
          opacity: [0, 1]
        },
        options: { duration: 400, easing: 'ease-in-out', fill: 'forwards' }
      })
    }
  })
}
