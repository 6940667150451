import Manipulator from 'lib/dom/manipulator'
import Fuse from 'fuse.js'

export const useFilterable = (controller) => {
  return Object.assign(controller, {
    getSearchableList() {
      return this.filterableTargets.map((item) => Manipulator.getDataAttribute(item, 'filterKeys')) // Shape like this [{key1, key2, ..., id}]
    },

    getOptionsKeys() {
      return Manipulator.getDataAttribute(this.element, 'optionsKeys')
    },

    getDefaultOptions() {
      return {
        threshold: 0.0,
        shouldSort: false,
        ignoreLocation: true,
        keys: this.getOptionsKeys()
      }
    },

    initFilterable() {
      this.results = this.getSearchableList()
      this.setupFuse()
    },

    setupFuse(options = this.getDefaultOptions()) {
      this.fuse = new Fuse(this.getSearchableList(), options)
    },

    search(query) {
      if (!query || query === '')
        this.results = this.filterableTargets.map((item) => ({
          item: { ...Manipulator.getDataAttribute(item, 'filterKeys'), id: item.id }
        }))
      else {
        this.results = this.fuse.search(query)
      }
      this.updateList()
    },

    updateList() {
      this.filterableTargets.forEach((target) => {
        const isInResult = this.results.find((result) => result.item.id === target.id)

        target.classList.toggle('hidden', !isInResult)
        target.setAttribute('data-hidden', !isInResult)
      })
    },

    resetList() {
      this.filterableTargets.forEach((target) => {
        target.classList.remove('hidden')
        target.setAttribute('data-hidden', false)
      })
    },

    visibleFilterableTargets() {
      return this.filterableTargets.filter((target) => !target.classList.contains('hidden'))
    }
  })
}
