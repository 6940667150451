import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form', 'bottlesPerSellingUnit', 'sellingUnitsPerCase', 'containerType', 'container', 'hiddenKegDeposit', 'deposit', 'hiddenDeposit']

  connect() {
    super.connect()
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('reset', () => this._handleFormReset());
    }
  }

  disconnect() {
    super.disconnect();
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener('reset', () => this._handleFormReset());
    }
  }

  confirm(event) {
    this.stimulate('Variant#toggle_variant_enabled', event.target)
  }

  onContainerTypeSelectChange() {
    if (this.fetching) return
    if (!this.containerTypeTarget.value) return
    this.fetching = fetch(`/container_types/${this.containerTypeTarget.value}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.may_charge_line_item_deposit) {
          if (this.hasContainerTarget) {
            this._showContainerInput()
            this._hideDepositInput()
          }
        } else {
          if (this.hasContainerTarget) {
            this._showDepositInput()
            this._hideContainerInput()
          }
        }

        if (data.container_type === 'keg') {
          this._disableSellingUnitInputs()
        } else {
          this._enableSellingUnitInputs()
        }

        this.fetching = false
      })
  }

  _handleFormReset() {
    setTimeout(() => this.onContainerTypeSelectChange(), 500)
  }

  _caseAndSellingUnitInputs() {
    return ['sellingUnitsPerCase', 'bottlesPerSellingUnit']
  }

  _enableSellingUnitInputs() {
    this._caseAndSellingUnitInputs().forEach(targetLabel => {
      const target = this[`${targetLabel}Target`]
      target.classList.remove('disabled')
      target.querySelector('input').readOnly = false
    })
  }

  _disableSellingUnitInputs() {
    this._caseAndSellingUnitInputs().forEach(targetLabel => {
      const target = this[`${targetLabel}Target`]
      target.classList.add('disabled')
      const input = target.querySelector('input')
      input.readOnly = true
      input.value = 1;
    })
  }

  _showContainerInput() {
    this._showTarget(this.containerTarget)
    this.containerTarget.removeAttribute('disabled')
    this.hiddenKegDepositTarget.setAttribute('disabled', '')
  }

  _hideContainerInput() {
    this.containerTarget.setAttribute('disabled', '')
    this._hideTarget(this.containerTarget)
    this.hiddenKegDepositTarget.removeAttribute('disabled')
  }

  _showDepositInput() {
    this._showTarget(this.depositTarget)
    this.depositTarget.removeAttribute('disabled')
    this.hiddenDepositTarget.setAttribute('disabled', '')
  }

  _hideDepositInput() {
    this.depositTarget.setAttribute('disabled', '')
    this._hideTarget(this.depositTarget)
    this.hiddenDepositTarget.removeAttribute('disabled')
  }

  _hideTarget(target) {
    target.style.display = "none"
  }

  _showTarget(target) {
    target.style.display = "block"
  }
}
