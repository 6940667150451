import ApplicationController from './application_controller'
import WebAnimation from 'lib/animation'
import Manipulator from 'lib/dom/manipulator'
import DOMEvents from 'lib/dom/events'
import { usePopper } from './mixins'

export default class extends ApplicationController {
  static targets = ['trigger', 'overlay']

  connect() {
    super.connect()
    usePopper(this)

    this.placement = Manipulator.getDataAttribute(this.element, 'placement') || 'top'
  }

  disconnect() {
    if (this.hasOverlayTarget) {
      if (!this.overlayTarget.classList.contains('hidden')) this.overlayTarget.classList.add('hidden')
      this._destroyPopper()
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === DOMEvents.keyCodes.ESC) this.hideTooltip()
  }

  showTooltip() {
    if (!this.hasOverlayTarget) return

    WebAnimation.enter(this.overlayTarget.getElementsByClassName('tooltip_component__overlay')[0], {
      keyframes: {
        opacity: [0, 1]
      },
      options: { duration: 100, easing: 'ease-out', fill: 'forwards' },
      elementToShow: this.overlayTarget
    })
    this._createPopper({ strategy: 'fixed' })
  }

  hideTooltip() {
    if (!this.hasOverlayTarget) return

    WebAnimation.leave(this.overlayTarget.getElementsByClassName('tooltip_component__overlay')[0], {
      keyframes: {
        opacity: [1, 0]
      },
      options: { duration: 75, easing: 'ease-in', fill: 'forwards' },
      elementToHide: this.overlayTarget,
      onfinish: this._destroyPopper
    })
  }
}
