import ApplicationController from './application_controller'
import Manipulator from 'lib/dom/manipulator'
import { usePopper } from './mixins/usePopper'

export default class extends ApplicationController {
  static targets = ['overlay', 'trigger']

  connect() {
    usePopper(this)

    this.placement = Manipulator.getDataAttribute(this.element, 'placement') || 'bottom-start'
  }

  disconnect() {
    if (!this.overlayTarget.classList.contains('hidden')) this.overlayTarget.classList.add('hidden')
    this._destroyPopper()
  }

  toggle = (e) => {
    e.preventDefault()
    if (this.overlayTarget.classList.contains('hidden')) {
      this._showOverlay()
    } else {
      this._hideOverlay()
    }
  }

  popperOpened = () => {
    window.addEventListener('keydown', this.closeWithKeyboard)
    window.addEventListener('click', this.hide)
  }

  popperClosed = () => {
    window.removeEventListener('keydown', this.closeWithKeyboard)
    window.removeEventListener('click', this.hide)
  }

  closeWithKeyboard = (e) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.hide(e)
    }
  }

  hide = (event) => {
    // skip if clicked element is the trigger or menu
    const controller = event.target.closest(`[data-controller="${this.identifier}"]`)

    if (this.element === controller) {
      return
    }

    if (this.show === true) this._hideOverlay()
  }
}
