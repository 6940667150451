import ApplicationController from './application_controller'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = ['form', 'spin']

  connect() {
    super.connect()
    useSpinner(this)
  }

  disconnect() {
    super.disconnect()
  }

  onInputUpdate() {
    this.showSpinner()
    this.formTarget.requestSubmit()
  }
}
