import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    this.subscription = consumer.subscriptions.create(
      {
        channel: 'ProductInventoryChannel',
        product_id: this.currentProductId,
        organization_id: this.currentOrganizationId
      },
      {
        received: (data) => {
          if (data.cableReady) {
            CableReady.perform(data.operations)
          }
        }
      }
    )

    document.addEventListener('stimulus-reflex:ready', this.getProductInventory, { once: true })
  }

  disconnect() {
    document.removeEventListener('stimulus-reflex:ready', this.getProductInventory.bind(this))
    this.unsubscribe()
  }

  getProductInventory = () => {
    this.subscription.send({ message: 'GET_INVENTORY' })
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
