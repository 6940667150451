import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// Change the config to fix the flicker
config.mutateApproach = 'sync'

// PLEASE KEEP LISTS ALPHABETICAL!
// Any conflicting names should be aliased using convention faIconNameVariant e.g. faCheckCircleLight
//
import {
  faFacebook,
  faGithub,
  faGoogle,
  faInstagram,
  faLinkedin,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

import {
  faAddressBook,
  faAnalytics,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faArrowDown,
  faArrowUp,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowRightFromArc,
  faArrowUpRightFromSquare,
  faBallPile,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBars,
  faBottleWater,
  faBoxOpen,
  faCalendarAlt,
  faCaretDown,
  faCartPlus,
  faCertificate,
  faChartMixed,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCircle,
  faCircleNotch,
  faCircleUser,
  faClipboardListCheck,
  faClock,
  faCog,
  faCoins,
  faCommentAltEdit,
  faCommentAltExclamation,
  faConveyorBeltBoxes,
  faCopy,
  faCreditCardFront,
  faCubesStacked,
  faDolly,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faFile,
  faGear,
  faGlobe,
  faHeadset,
  faHorizontalRule,
  faLink,
  faList,
  faFileChartLine,
  faFilter,
  faInbox,
  faIndustryAlt,
  faInfoCircle,
  faLightbulb,
  faLock,
  faUnlock,
  faLongArrowAltDown,
  faLongArrowAltRight,
  faMap,
  faMemoCircleCheck,
  faMinus,
  faMobileAlt,
  faMoneyCheckEdit,
  faPaperPlane,
  faPencil,
  faPencilRuler,
  faPercent,
  faPhone,
  faPlus,
  faQuestionCircle,
  faRectanglesMixed,
  faRightFromBracket,
  faRightLeft,
  faRoute,
  faSearch,
  faShippingFast,
  faShippingTimed,
  faSignOut,
  faSliders,
  faSort,
  faSparkles,
  faSpinnerThird,
  faStickyNote,
  faStar,
  faStore,
  faSync,
  faTableList,
  faTag,
  faTags,
  faTh,
  faThList,
  faTimes,
  faTimesCircle,
  faTrash,
  faTruck,
  faTurnDownLeft,
  faUndoAlt,
  faUnlink,
  faUser,
  faUserGroup,
  faUserCheck,
  faUsers,
  faUsdSquare,
  faWarehouseAlt,
  faWineBottle,
  faJug,
  faWrench,
  faReceipt,
  faQuestion
} from '@fortawesome/pro-regular-svg-icons'

import {
  faArrowUpRightFromSquare as faArrowUpRightFromSquareLight,
  faCheckCircle as faCheckCircleLight,
  faCircleUser as faCircleUserLight,
  faEllipsisH as faEllipsisHLight,
  faEllipsisV as faEllipsisVLight,
  faGear as faGearLight,
  faInfoCircle as faInfoCircleLight,
  faMinus as faMinusLight,
  faPlus as faPlusLight,
  faRightFromBracket as faRightFromBracketLight,
  faSparkles as faSparklesLight,
  faStore as faStoreLight,
  faTimesCircle as faTimesCircleLight,
  faMoneyCheckDollarPen as faMoneyCheckDollarPenLight,
  faCreditCard as faCreditCardLight,
  faCommentsDollar as faCommentsDollarLight
} from '@fortawesome/pro-light-svg-icons'

import {
  faAngleDown as faAngleDownSolid,
  faAngleRight as faAngleRightSolid,
  faAngleLeft as faAngleLeftSolid,
  faArrowLeft,
  faArrowToTop,
  faBadgeCheck,
  faBan,
  faBars as faBarsSolid,
  faCalendar,
  faCalendarAlt as faCalendarAltSolid,
  faCaretDown as faCaretDownSolid,
  faCaretLeft,
  faCaretRight,
  faChevronDown,
  faChevronUp,
  faCheck as faCheckSolid,
  faCheckCircle as faCheckCircleSolid,
  faCheckDouble as faCheckDoubleSolid,
  faCheckSquare,
  faCircle as faCircleSolid,
  faClock as faClockSolid,
  faCommentAltEdit as faCommentAltEditSolid,
  faDollarSign as faDollarSignSolid,
  faDolly as faDollySolid,
  faEllipsisH as faEllipsisHSolid,
  faEnvelope as faEnvelopeSolid,
  faExchange as faExchangeSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faExclamationSquare,
  faFilter as faFilterSolid,
  faInfoCircle as faInfoCircleSolid,
  faGripHorizontal,
  faMinus as faMinusSolid,
  faLock as faLockSolid,
  faPaperPlane as faPaperPlaneSolid,
  faPencilRuler as faPencilRulerSolid,
  faPhone as faPhoneSolid,
  faPlus as faPlusSolid,
  faShippingFast as faShippingFastSolid,
  faShippingTimed as faShippingTimedSolid,
  faSort as faSortSolid,
  faSpinnerThird as faSpinnerThirdSolid,
  faStar as faStarSolid,
  faStore as faStoreSolid,
  faTriangleExclamation,
  faTimes as faTimesSolid,
  faTimesCircle as faTimesCircleSolid,
  faTrash as faTrashSolid,
  faTruck as faTruckSolid,
  faUsdCircle
} from '@fortawesome/pro-solid-svg-icons'

import {
  faAbacus,
  faBell,
  faBoxOpen as faBoxOpenDuotone,
  faCheckCircle as faCheckCircleDuotone,
  faComments,
  faCreditCard as faCreditCardDuotone,
  faEmptySet,
  faExclamationCircle as faExclamationCircleDuotone,
  faFileInvoiceDollar,
  faFileMinus,
  faFilePlus,
  faHandHoldingUsd,
  faInbox as faInboxDuo,
  faInfoCircle as faInfoCircleDuotone,
  faLevelDown,
  faLevelUp,
  faMoneyCheckAlt as faMoneyCheckAltDuotone,
  faPlug,
  faProjectDiagram,
  faShoppingCart,
  faSlidersVSquare,
  faSortDown,
  faSortUp,
  faSpinnerThird as faSpinnerThirdDuotone,
  faStore as faStoreDuo,
  faTimesCircle as faTimeCircleDuotone,
  faTimesCircle as faTimesCircleDuotone,
  faTruck as faTruckDuotone,
  faUsersCog,
  faUsersSlash,
  faWarehouseAlt as faWarehouseAltDuo,
  faFilePdf,
  faSplit
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faAddressBook,
  faAbacus,
  faAnalytics,
  faAngleDown,
  faAngleDownSolid,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faAngleRightSolid,
  faAngleLeftSolid,
  faArrowDown,
  faArrowLeft,
  faArrowToTop,
  faArrowUp,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowRightFromArc,
  faArrowUpRightFromSquare,
  faArrowUpRightFromSquareLight,
  faBadgeCheck,
  faBallPile,
  faBan,
  faBars,
  faBarsSolid,
  faBell,
  faBoxOpen,
  faBottleWater,
  faBoxOpenDuotone,
  faCalendar,
  faCalendarAlt,
  faCalendarAltSolid,
  faCaretDown,
  faCaretDownSolid,
  faCaretLeft,
  faCaretRight,
  faCartPlus,
  faCertificate,
  faChartMixed,
  faCheck,
  faCheckSolid,
  faCheckCircle,
  faCheckCircleDuotone,
  faCheckCircleLight,
  faCheckCircleSolid,
  faCheckDouble,
  faCheckDoubleSolid,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCircleSolid,
  faCircleUser,
  faCircleUserLight,
  faClipboardListCheck,
  faClock,
  faClockSolid,
  faCog,
  faCoins,
  faCommentAltEdit,
  faCommentAltEditSolid,
  faCommentAltExclamation,
  faComments,
  faCommentsDollarLight,
  faConveyorBeltBoxes,
  faCopy,
  faCreditCardDuotone,
  faCreditCardFront,
  faCreditCardLight,
  faCubesStacked,
  faDollarSign,
  faDollarSignSolid,
  faDolly,
  faDollySolid,
  faEdit,
  faEllipsisH,
  faEllipsisHLight,
  faEllipsisHSolid,
  faEllipsisVLight,
  faEmptySet,
  faEnvelope,
  faExchange,
  faExchangeSolid,
  faExclamationCircle,
  faExclamationCircleDuotone,
  faExclamationCircleSolid,
  faExclamationSquare,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faFacebook,
  faFile,
  faFileChartLine,
  faFileInvoiceDollar,
  faFileMinus,
  faFilePdf,
  faFilePlus,
  faFilter,
  faFilterSolid,
  faGear,
  faGearLight,
  faGithub,
  faGlobe,
  faGoogle,
  faGripHorizontal,
  faHeadset,
  faHandHoldingUsd,
  faHorizontalRule,
  faInbox,
  faIndustryAlt,
  faInboxDuo,
  faInfoCircle,
  faInfoCircleDuotone,
  faInfoCircleLight,
  faInfoCircleSolid,
  faInstagram,
  faLightbulb,
  faLink,
  faLinkedin,
  faList,
  faLevelDown,
  faLevelUp,
  faLock,
  faLockSolid,
  faUnlock,
  faLongArrowAltDown,
  faLongArrowAltRight,
  faMap,
  faMemoCircleCheck,
  faMinus,
  faMinusLight,
  faMinusSolid,
  faMobileAlt,
  faMoneyCheckAltDuotone,
  faMoneyCheckDollarPenLight,
  faMoneyCheckEdit,
  faPaperPlane,
  faPaperPlaneSolid,
  faPencil,
  faPencilRuler,
  faPencilRulerSolid,
  faPercent,
  faPhone,
  faPhoneSolid,
  faPlug,
  faPlus,
  faPlusLight,
  faPlusSolid,
  faProjectDiagram,
  faQuestionCircle,
  faRectanglesMixed,
  faRightFromBracket,
  faRightFromBracketLight,
  faRightLeft,
  faRoute,
  faSearch,
  faShippingFast,
  faShippingFastSolid,
  faShippingTimed,
  faShippingTimedSolid,
  faShoppingCart,
  faSignOut,
  faSliders,
  faSlidersVSquare,
  faSort,
  faSortSolid,
  faSortDown,
  faSortUp,
  faSparkles,
  faSparklesLight,
  faSpinnerThird,
  faSpinnerThirdDuotone,
  faSpinnerThirdSolid,
  faStar,
  faStarSolid,
  faStickyNote,
  faStore,
  faStoreLight,
  faStoreDuo,
  faStoreSolid,
  faSync,
  faSplit,
  faTableList,
  faTag,
  faTags,
  faTh,
  faThList,
  faTimeCircleDuotone,
  faTimes,
  faTimesCircle,
  faTimesCircleLight,
  faTimesCircleSolid,
  faTimesCircleDuotone,
  faTimesSolid,
  faTrash,
  faTrashSolid,
  faTriangleExclamation,
  faTruck,
  faTruckSolid,
  faTruckDuotone,
  faTurnDownLeft,
  faTwitter,
  faUndoAlt,
  faUnlink,
  faUsdCircle,
  faUser,
  faUserGroup,
  faUserCheck,
  faUsers,
  faUsersCog,
  faUsersSlash,
  faUsdSquare,
  faWarehouseAlt,
  faWarehouseAltDuo,
  faWineBottle,
  faJug,
  faWrench,
  faReceipt,
  faQuestion
)

dom.watch()
document.addEventListener('cable-ready:after-morph', () => dom.i2svg())
