import ApplicationController from './application_controller'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = ['spin', 'container']

  disconnect() {
    super.disconnect()
    this.tearDownSpinner()
  }

  connect() {
    super.connect()
    useSpinner(this)
  }

  spin() {
    this.showSpinner(this.spinTarget, this.containerTarget)
  }
}
