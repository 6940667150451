import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['priceListSelect', 'pricingGroupSelect']

  updatePriceList(event) {
    if (!this.hasPriceListSelectTarget) { return  }

    const state_id = event.target.selectedOptions[0].value
    const target = this.priceListSelectTarget.id

    get(`/states/price_lists?state_id=${state_id}&target=${target}`, {
      responseKind: 'turbo-stream'
    })
  }

  updatePricingGroupSelect(event) {
    if (!this.hasPricingGroupSelectTarget) { return  }

    const state_id = event.target.selectedOptions[0].value
    const target = this.pricingGroupSelectTarget.id

    get(`/states/${state_id}/licensed_pricing_groups?target=${target}`, {
      responseKind: 'turbo-stream'
    })
  }
}
