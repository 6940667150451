import ApplicationController from './application_controller'
import { useOverflowNavigation } from './mixins'

export default class extends ApplicationController {
  static targets = ['tab', 'tabsContainer', 'leftButton', 'rightButton']

  connect() {
    useOverflowNavigation(this)
    super.connect()

    if (this.activeTab) {
      this._scrollToTab(this.activeTab)
    }
  }

  onScrollLeftBtnClick() {
    this._scrollLeft(this.tabsContainer)
  }

  onScrollRightBtnClick() {
    this._scrollRight(this.tabsContainer)
  }

  get activeTab() {
    return this.tabTargets.find((target) => target.getAttribute('data-active') === 'true')
  }
}
