import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    this.submitTimeout = setTimeout(() => this.element.submit(), 250)
  }

  disconnect() {
    this.clearTimeout(this.submitTimeout)
  }
}
