const MOBILE_BREAKPOINT = 640

class DOM {
  static findElement(root, selector) {
    if (typeof root === 'string') {
      selector = root
      root = document
    }
    return root.querySelector(selector)
  }

  static removeElement(el) {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el)
    }
  }

  static insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
  }

  static getWindowFullHeight() {
    const body = document.body
    const html = document.documentElement

    return Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
  }

  static isMobile() {
    return window.innerWidth < 640
  }
}

export default DOM
