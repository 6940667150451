import ApplicationController from './application_controller'
import hotkeys from 'hotkeys-js'

export default class extends ApplicationController {
  static values = { actions: Object }

  connect() {
    this.createShortcutsListeners()
  }

  disconnect() {
    hotkeys.unbind()
  }

  createShortcutsListeners() {
    Object.keys(this.actionsValue).forEach((key) => {
      hotkeys(key, () => {
        const url = this.actionsValue[key].url
        if (url) {
          window.location.href = url
        }
      })
    })
  }
}
