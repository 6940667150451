import ApplicationController from './application_controller'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = ['collectionCheck', 'collectionCheckAll', 'action', 'spin', 'container']

  connect() {
    super.connect()
    useSpinner(this)
  }

  disconnect() {
    super.disconnect()
    this.tearDownSpinner()
  }

  selectAll(event) {
    event.stopPropagation()
    this._toggleCheckedTargets(event.target.checked)
    this._enableActionItems(event.target.checked)
  }

  itemChecked() {
    this.collectionCheckAllTarget.checked = this.allChecked
    this.collectionCheckAllTarget.indeterminate = this.anyChecked && !this.allChecked

    this._enableActionItems(this.anyChecked)
  }

  toggleState(event) {
    if (event.currentTarget.classList.contains('disabled')) { return }
    event.preventDefault()
    this.showSpinner()

    const reflex = event.target.dataset.reflexName

    event.target.dataset.resourceIds = JSON.stringify(this.checkedCollectionChecks.map((el) => el.id))
    event.target.checked = event.target.id === 'toggle-enable'
    this.stimulate(reflex, event.target, { selectors: this.morphSelectors })

    this._resetBatchActions()
  }

  reflexSuccess() {
    this.hideSpinner()
  }

  reflexError() {
    this.hideSpinner()
  }

  get morphSelectors() {
    const overrideId = '#batch-actions-reflex-root'
    const containsOverride = document.querySelector(overrideId)
    return containsOverride ? overrideId : ''
  }

  get allChecked() {
    return this.checkedCollectionChecks.length === this.collectionCheckTargets.length
  }

  get anyChecked() {
    return this.checkedCollectionChecks.length > 0
  }

  get checkedCollectionChecks() {
    return this.collectionCheckTargets.filter((el) => el.checked)
  }

  _resetBatchActions() {
    this.collectionCheckAllTarget.checked = false
    this.collectionCheckAllTarget.indeterminate = false
    this._toggleCheckedTargets(false)
    this._enableActionItems(false)
  }

  _toggleCheckedTargets(checked) {
    this.collectionCheckTargets.forEach((checkbox) => (checkbox.checked = checked))
  }

  _enableActionItems(checked) {
    this.actionTargets.forEach((action) => action.classList.toggle('disabled', !checked))
  }
}
