import ApplicationController from '../application_controller'
import debounced from 'debounced'
import currency from 'currency.js'

export default class extends ApplicationController {
  static targets = [
    'paymentSourceInputHidden',
    'paymentTypeInput',
    'paymentTypeInputHidden',
    'chargeInput',
    'chargeInputContainer',
    'balance',
    'submitButton',
    'paymentOptionsForm',
    'credits',
    'selectedCreditTotal',
    'paymentDatePicker'
  ]

  initialize() {
    super.initialize()
    debounced.initialize()
  }

  connect() {
    super.connect()
    this.setChargeButtonAmountText()
    this.updateTotals()
  }

  setPaymentType({ params }) {
    this.paymentTypeInputHiddenTarget.value = params.paymentType
  }

  submitForm(event) {
    this.toggleSubmit()
    this.submitButtonTarget.innerText = `Charging...`
  }

  onAmountChange() {
    this.updateTotals()
    this.setChargeButtonAmountText()
  }

  updateBalance() {
    var newBalance = this.computeBalance()
    if (newBalance < 0) {
      newBalance = currency(0)
    }
    this.balanceTarget.innerHTML = newBalance
  }

  setChargeButtonAmountText() {
    var chargeAmount = this.chargeAmount()
    if (!isNaN(chargeAmount)) {
      this.submitButtonTarget.innerText = `Charge $${chargeAmount}`
    }
  }

  initialBalance() {
    return currency(this.balanceTarget.dataset.balance)
  }

  currentBalance() {
    return currency(this.balanceTarget.innerHTML)
  }

  computeBalance() {
    return this.initialBalance()
      .subtract(this.chargeAmount())
      .subtract(this.selectedCreditTotal())
  }

  updateCreditTotal() {
    if (this.hasSelectedCreditTotalTarget) {
      this.selectedCreditTotalTarget.innerHTML = this.selectedCreditTotal()
    }
  }

  normalizeChargeAmount() {
    this.chargeInputTarget.value = this.initialBalance().subtract(this.selectedCreditTotal())
  }

  chargeAmount() {
    return currency(this.chargeInputTarget.value)
  }

  onCreditChange() {
    // If the remaining balance was previously zero, or we are about to
    // cause an overchage with credits, keep the balance at zero.
    if (this.currentBalance() <= 0) {
      this.normalizeChargeAmount()
    }

    this.updateTotals()
  }

  updateTotals() {
    this.updateCreditTotal()
    this.updateBalance()
  }

  selectedCreditTotal() {
    if (!this.hasCreditsTarget) {
      return currency(0)
    }

    return Array.from(this.creditsTarget.children)
      .filter((credit) => credit.selected)
      .reduce(
        (creditTotal, currentChild) => creditTotal.add(currency(currentChild.innerHTML)),
        currency(0)
      )
  }

  toggleSubmit(disable = true) {
    const classes = ['disabled', 'cursor-not-allowed', 'pointer-events-none']
    classes.map((klass) => this.submitButtonTarget.classList.toggle(klass, disable))
  }

  handleErrors() {
    this.toggleSubmit(false)
  }
}
