import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

// Morphdom diff's and replaces only changed nodes,
// in order to re-connect stale stimulus controller elements we need to
// disconnect/reconnect them to Stimulus before morph.
// Usage - add data-morph-discard to a Stimulus controller element to force reconnect
document.addEventListener('cable-ready:before-morph', () => {
  document.querySelectorAll('[data-controller][data-morph-discard]').forEach((node) => {
    const controllerIdentifier = node.dataset.controller
    if (!controllerIdentifier)
      throw `controller not attached to element, add super.connect() to ${controllerIdentifier}`
    return node[controllerIdentifier]?.disconnect()
  })
})
document.addEventListener('cable-ready:after-morph', () => {
  document.querySelectorAll('[data-controller][data-morph-discard]').forEach((node) => {
    const controller = node[node.dataset.controller]
    return controller?.connect()
  })
})

document.addEventListener('turbo:before-cache', function() {
  // Prevent turbo previews from rendering unfinished notifications
  document.querySelectorAll('.iziToast-wrapper').forEach((node) => {
    node.remove()
  })
  document.querySelectorAll('#alert_container_frame').forEach((node) => {
    node.innerHTML = ''
  })
})

document.addEventListener('wheel', function(event) {
  let activeElement = document.activeElement
  if (activeElement.type === "number" && activeElement.classList.contains('no-wheel-scroll')) {
    activeElement.blur();
  }
});

export default class extends Controller {
  initialize() {
    this.alertAnnouncer = window.alertAnnouncer
  }

  connect() {
    // ensure to call super.connect() in inherited controllers to use StimulusReflex
    this.element[this.identifier] = this
    StimulusReflex.register(this)
  }

  /*
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */
  // eslint-disable-next-line no-unused-vars
  beforeReflex(element, reflex) {}
  // eslint-disable-next-line no-unused-vars
  reflexSuccess(element, reflex, error) {
    // show success message etc...
  }
  // eslint-disable-next-line no-unused-vars
  reflexError(element, reflex, error) {
    // show error message etc...
  }
  // eslint-disable-next-line no-unused-vars
  afterReflex(element, reflex) {}

  handleError = (error) => {
    const context = {
      controller: this.identifier,
      organization_id: this.currentOrganizationId,
      customer_id: this.currentCustomerId,
      organization_membership: this.currentOrganizationMembership
    }
    this.application.handleError(error, `Error in controller: ${this.identifier}`, context)
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }

  get currentOrganizationId() {
    return document.head.querySelector('meta[name="current-organization-id"]').content
  }

  get currentOrderId() {
    if (document.head.querySelector('meta[name="current-order-return-id"]')) {
      return document.head.querySelector('meta[name="current-order-return-id"]').content
    } else {
      return document.head.querySelector('meta[name="current-order-id"]').content
    }
  }

  get currentProductId() {
    return document.head.querySelector('meta[name="current-product-id"]').content
  }

  get currentCustomerId() {
    return document.head.querySelector('meta[name="current-customer-id"]').content
  }

  get currentOrganizationMembership() {
    if (document.head.querySelector('meta[name="current-organization-membership"]')) {
      return document.head.querySelector('meta[name="current-organization-membership"]').content
    }
  }

  showClosestSpinner(element) {
    const el = element.closest('div[data-controller="spin-component"]')
    el && el['spin-component'].showSpinner()
  }

  hideClosestSpinner() {
    const el = document.querySelector('div[data-controller="spin-component"]')
    el && el['spin-component'].hideSpinner()
  }
}
