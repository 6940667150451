import ApplicationController from './application_controller'
import { useTableSpinner } from 'controllers/mixins'

export default class extends ApplicationController {
  static targets = ['tableSpinner']

  connect() {
    super.connect()

    useTableSpinner(this)
  }

  beforeReflex() {
    this._showTableSpinner()
  }

  afterReflex() {
    this._hideTableSpinner()
  }

  current(event) {
    event.preventDefault()
    this.stimulate('customer_actions#current', event.currentTarget)
  }

  prospect(event) {
    event.preventDefault()
    this.stimulate('customer_actions#prospect', event.currentTarget)
  }

  archive(event) {
    event.preventDefault()
    this.stimulate('customer_actions#archive', event.currentTarget)
  }

  potential(event) {
    event.preventDefault()
    this.stimulate('customer_actions#potential', event.currentTarget)
  }
}
