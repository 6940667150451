import ScriptHelper from 'lib/dom/script_helper'
import Rails from '@rails/ujs'

export const useSpreedly = (controller) => {
  Object.assign(controller, {
    initSpreedly(onError = () => {}) {
      ScriptHelper.appendScript(this.element, this.setupSpreedly.bind(this, onError))
    },

    submitSpreedly() {
      this.tokenizeCard()
    },

    // Private
    setupSpreedly(onError) {
      this.spreedly = window.Spreedly
      this.spreedly.init(this.environmentKey(), {
        numberEl: 'card-number', // becomes this.spreedly.numberTarget
        cvvEl: 'card-cvv' // becomes this.spreedly.cvvTarget
      })
      this.addSpreedlyCallbacks(onError)
    },

    addSpreedlyCallbacks(onError) {
      // A 'ready’ event is triggered when the iFrames have been successfully initialized
      this.spreedly.on('ready', () => this.setStyles())

      this.spreedly.on('errors', (errors) => {
        document.addEventListener('keydown', () => this.resetForm())
        this.renderErrors(errors)
        onError(errors)
      })

      this.spreedly.on('paymentMethod', (token, pmData) =>
        this.populateAndSubmitForm(token, pmData)
      )
    },

    tokenizeCard() {
      // CC number and CVV are automatically handled by Spreedly
      const paymentMethodFields = ['full_name', 'year', 'month', 'zip']
      const options = paymentMethodFields.reduce((acc, field) => {
        return { ...acc, [field]: this.spreedlyPaymentForm().elements[field].value }
      }, {})

      this.spreedly.tokenizeCreditCard(options)
    },

    populateAndSubmitForm(token, pmData) {
      for (const [key, value] of Object.entries(pmData)) {
        const el = this.spreedlyPaymentForm().elements[key]
        if (el && !el.value) el.value = value
      }

      Rails.fire(this.spreedlyPaymentForm(), 'submit')
    },

    setStyles() {
      this.spreedly.setFieldType('number', 'text')
      this.spreedly.setFieldType('cvv', 'text')
      this.spreedly.setPlaceholder('number', 'Card number')
      this.spreedly.setPlaceholder('cvv', 'CVV')

      let inputStyles =
        'background: white; line-height: 1.5rem; font-size: 14px; padding: 0; width: 100%;'

      this.spreedly.setStyle('number', inputStyles)
      this.spreedly.setStyle('cvv', inputStyles)
    },

    renderErrors(errors) {
      const errorContainer = document.getElementById('pop-message')
      errorContainer.innerHTML = ''

      errors.forEach((error) => {
        if (error['attribute']) this.setInputErrorBorder(error['attribute'])

        const errorMessage = document.createElement('span')
        errorMessage.innerHTML = error['message']
        errorContainer.appendChild(errorMessage)
      })
    },

    setInputErrorBorder(attribute) {
      let formElement;

      if (attribute === 'number') {
        formElement = document.getElementById('card-number-container')
      } else if (attribute === 'first_name' || attribute === 'last_name') {
        formElement = document.getElementById('full_name')
      } else {
        formElement = this.spreedlyPaymentForm().elements[attribute]
      }

      if (formElement) {
        formElement.classList.add('border-red-500')
      }
    },

    resetForm() {
      if (document.getElementById('pop-message')) {
        document.getElementById('pop-message').textContent = ''
        this.setStyles()
      }
    },

    environmentKey() {
      return this.element.dataset.environmentKey
    },
    scriptSrc() {
      return this.element.dataset.scriptSrc
    },
    spreedlyPaymentForm() {
      return this.element.querySelector('form')
    }
  })
}
