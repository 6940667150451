import ApplicationController from './application_controller'
import debounced from 'debounced'
import { useEditableForm, useSpinner } from './mixins'
import Manipulator from 'lib/dom/manipulator'

export default class extends ApplicationController {
  static targets = ['editable', 'commitButton', 'editButton', 'cancelButton', 'form', 'container', 'spin']

  initialize() {
    super.initialize()
    debounced.initialize()
  }

  connect() {
    super.connect()
    useEditableForm(this)
    useSpinner(this)
    this.element.addEventListener('upload-processing', this.dropZoneProcessing.bind(this))
    this.element.addEventListener('upload-complete', this.dropZoneComplete.bind(this));
  }

  disconnect() {
    this.tearDownSpinner()
  }

  dropZoneProcessing(event) {
    this.toggleCommitButtonAbility(true)
    event.stopPropagation()
  }

  dropZoneComplete(event) {
    this.toggleCommitButtonAbility(true)
    event.stopPropagation()
  }

  toggleState(event) {
    if (this.commitButtonTarget === event.currentTarget) return this.submitForm()
    if (this.cancelButtonTarget === event.currentTarget) this.resetForm()
    if (this.hasErrors) return

    if (this.editOnly) {
      this.formTarget.reset()
      this.toggleCommitButtonAbility(true)
    } else {
      this.toggleEditableSlots()
      this.toggleActionButtons()
    }
  }

  toggleActionButtons() {
    if (this.hasEditButtonTarget) this.editButtonTarget.classList.toggle('hidden')
    this.cancelButtonTarget.classList.toggle('hidden')
    this.commitButtonTarget.classList.toggle('hidden')
  }

  toggleEditableSlots() {
    this.editableTargets.forEach((target) => {
      target.classList.toggle('hidden')
    })
  }

  submitForm() {
    this.showSpinner()
    this.formTarget.requestSubmit()
  }

  resetForm() {
    this.formTarget.reset()
  }

  onSubmitSuccess() {
    this.resetForm()
    this.toggleEditableSlots()
    this.toggleActionButtons()
  }

  onSubmitError(event) {
    // NOOP
  }

  handleErrors() {
    this.hasErrors = true
    if (this.editOnly) return
    this.toggleEditableSlots()
    this.toggleActionButtons()
  }

  get editOnly() {
    return Manipulator.getDataAttribute(this.containerTarget, 'editOnly')
  }
}
