import ApplicationController from './application_controller'
import DOMEvents from 'lib/dom/events'

export default class extends ApplicationController {
  static targets = ['input', 'incrementBtn', 'decrementBtn']
  static values = { max: Number, min: Number, step: Number }

  initialize() {}

  connect() {
    this.changeEvent = new Event('input')
    this.incrementEvent = new Event('decrement')
    this.decrementEvent = new Event('increment')

    this._updateButtons()
  }

  disconnect() {}

  get inputValue() {
    return +this.inputTarget.value
  }

  set inputValue(val) {
    this.inputTarget.value = val
  }

  get inputValid() {
    return this.inputTarget.validity.valid
  }

  get max() {
    return this.hasMaxValue ? this.maxValue : Infinity
  }

  get min() {
    return this.hasMinValue ? this.minValue : -Infinity
  }

  onChange() {
    if (!this.inputValid) this.element.setAttribute('data-invalid', true)
    else this.element.setAttribute('data-invalid', false)
    this._updateButtons()
  }

  onIncrementButtonClick(event) {
    event.stopPropagation()
    event.preventDefault()
    this._increment()
    this.inputTarget.animate(
      [
        {
          transform: 'translateY(0%)',
          opacity: 1
        },
        { transform: 'translateY(-2px)', opacity: 0.75 },
        { transform: 'translateY(0%)', opacity: 1 }
      ],
      { duration: 250, easing: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)' }
    )
  }

  onDecrementButtonClick(event) {
    event.stopPropagation()
    event.preventDefault()
    this._decrement()
    this.inputTarget.animate(
      [
        {
          transform: 'translateY(0%)',
          opacity: 1
        },
        { transform: 'translateY(2px)', opacity: 0.75 },
        { transform: 'translateY(0%)', opacity: 1 }
      ],
      { duration: 250, easing: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)' }
    )
  }

  _increment() {
    if (this.inputValue === null) {
      this.inputValue = 0 + this.stepValue
    }
    const newValue = this.inputValue + this.stepValue
    if (newValue > this.max) return

    this.inputValue = newValue
    this.inputTarget.dispatchEvent(this.changeEvent)
    this.inputTarget.dispatchEvent(this.incrementEvent)
  }

  _decrement() {
    if (this.inputValue === null) {
      this.inputValue = 0
    }
    const newValue = this.inputValue - this.stepValue
    if (newValue < this.min) return

    this.inputValue = newValue
    this.inputTarget.dispatchEvent(this.changeEvent)
    this.inputTarget.dispatchEvent(this.decrementEvent)
  }

  _disabledBtn(button) {
    button.disabled = true
  }

  _enabledBtn(button) {
    button.disabled = false
  }

  _updateButtons(value = this.inputValue) {
    if (value + this.stepValue > this.max) this._disabledBtn(this.incrementBtnTarget)
    else {
      this._enabledBtn(this.incrementBtnTarget)
    }

    if (value - this.stepValue < this.min) this._disabledBtn(this.decrementBtnTarget)
    else {
      this._enabledBtn(this.decrementBtnTarget)
    }
  }

  _focus() {
    this.inputTarget.focus()
  }
}
