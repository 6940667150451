import ApplicationController from './application_controller'
import { useSelect } from './mixins/useSelect'

export default class extends ApplicationController {
  static targets = ['select', 'options', 'selectContainer']

  connect() {
    super.connect()

    useSelect(this)
    this.prepareChoices()
  }

  prepareChoices() {
    this.initializeChoices()
    this.connectChoices()
  }

  disconnect() {
    super.disconnect()

    this.disconnectChoices()
  }
}
