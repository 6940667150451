import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  static targets = ['clearBtn', 'calendarIcon']
  static values = {
    mode: String,
    enabletime: Boolean,
    altformat: String,
    altinput: Boolean,
    defaultdate: String || Array
  }

  connect() {
    this.element[this.identifier] = this
    this.config = {
      dateFormat: 'Y-m-d H:i',
      allowInput: false,
      mode: this.modeValue,
      enableTime: this.enabletimeValue,
      altFormat: this.altformatValue,
      altInput: this.altinputValue,
      defaultDate: this.defaultdateValue,
      disableMobile: true
    }

    super.connect()

    this.styleSelect()
    this.styleYearInput()
  }

  containerToggle() {
    this.inputTarget.focus()
  }

  showClearButton() {
    if (this.fp && this.fp.selectedDates.length === 2) {
      this.clearBtnTarget.classList.toggle('hidden')
      this.calendarIconTarget.classList.toggle('hidden')
    }
  }

  hideClearButton() {
    if (!this.clearBtnTarget.classList.contains('hidden')) {
      this.clearBtnTarget.classList.toggle('hidden')
      this.calendarIconTarget.classList.toggle('hidden')
    }
  }

  clear(e) {
    e.preventDefault()
    if (this.fp) {
      this.fp.clear()
      this.hideClearButton()
    }
  }

  styleSelect() {
    if (this.monthNavTarget) {
      const selectElement = this.monthNavTarget.childNodes[1].childNodes[0].childNodes[0]
      selectElement.classList.remove('flatpickr-monthDropdown-months')
      selectElement.classList.add(
        'form-select',
        'pt-1',
        'pb-1',
        'mr-2',
        'border-gray-200',
        'hover:border-gray-300',
        'focus:border-primary'
      )
    }
  }

  styleYearInput() {
    if (this.monthNavTarget) {
      const inputElement = this.monthNavTarget.childNodes[1].childNodes[0].childNodes[1]
        .childNodes[0]
      inputElement.classList.remove('cur-year')
      inputElement.classList.add(
        'form-input',
        'pt-1',
        'pb-1',
        'border-gray-200',
        'hover:border-gray-300',
        'focus:border-primary'
      )
    }
  }
}
