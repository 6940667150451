// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import StimulusReflex from 'stimulus_reflex'

import consumer from '../channels/consumer'
import controller from './application_controller'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)

// require stimulus controllers sidecared/co-located in the app/components directory
const context_components = require.context('../../components/', true, /_controller.js$/)
application.load(definitionsFromContext(context).concat(definitionsFromContext(context_components)))

StimulusReflex.initialize(application, {
  consumer,
  debug: process.env.NODE_ENV === 'development',
  isolate: false
})
application.consumer = consumer
