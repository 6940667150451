import ApplicationController from './application_controller'
import { useSelect } from './mixins/useSelect'

export default class extends ApplicationController {
  static targets = ['select', 'options', 'selectContainer']

  connect() {
    super.connect()

    useSelect(this)

    this.initializeChoices()
    this.connectChoices()
  }

  disconnect() {
    this.disconnectChoices()
  }

  submitForm(event) {
    event.target.form.requestSubmit();
  }
}
