import ApplicationController from './application_controller'

require('cleave.js/dist/addons/cleave-phone.ca')

import Cleave from 'cleave.js'

export default class extends ApplicationController {
  static targets = ['input']

  initialize() {
    this._initCleave()
  }

  disconnect() {
    this.cleave?.destroy()
  }

  change(event) {
    // placeholder
  }

  _maskNumberInput(target, options = {}) {
    return new Cleave(target, {
      numeral: true,
      stripLeadingZeroes: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      ...options
    })
  }

  _initCleave() {
    switch (this.inputTarget.dataset.variant) {
      case 'currency':
        let allowNegative = (this.inputTarget.dataset.allowNegative && this.inputTarget.dataset.allowNegative === 'true') || false
        this.cleave = this._maskNumberInput(this.inputTarget,{ numeralPositiveOnly: !allowNegative })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      case 'quantity':
        this.cleave = this._maskNumberInput(this.inputTarget, {
          numeralDecimalScale: 0,
          numeralThousandsGroupStyle: 'none'
        })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      case 'percent':
        this.cleave = this._maskNumberInput(this.inputTarget, {
          numeralDecimalScale: 1
        })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      case 'phone':
        this.cleave = new Cleave(this.inputTarget, { phone: true, phoneRegionCode: 'CA' })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      case 'credit-card':
        this.cleave = new Cleave(this.inputTarget, { creditCard: true })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      case 'year':
        this.cleave = new Cleave(this.inputTarget, { date: true, datePattern: ['Y'] })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      case 'month':
        this.cleave = new Cleave(this.inputTarget, { date: true, datePattern: ['m'] })
        if (this.inputTarget.value) this.cleave.setRawValue(this.inputTarget.value)
        break
      default:
        this.inputTarget
    }
  }
}
