import ApplicationController from './application_controller'
import { useSelect, useSpinner, useActionsBar } from './mixins'

export default class extends ApplicationController {
  static targets = [
    'options',
    'select',
    'selectContainer',
    'brandSelect',
    'customerSelectForm',
    'sellerSelectForm',
    'details',
    'priceListSelect',
    'warehouseSelect',
    'warehouseSelectSpin',
    'warehouseSelectContainer',
    'fulfillmentMethodSelect',
    'fulfillmentMethodSelectSpin',
    'fulfillmentMethodSelectContainer',
    'calculatingPriceContainer',
    'calculatingPriceSpin',
    'updatePriceListForm',
    'updateWarehouseForm'
  ]

  connect() {
    super.connect()

    if (this.hasSelectTarget && this.hasBrandSelectTarget && this.hasCustomerSelectFormTarget) {
      if (this.selectTarget.value && this.brandSelectTarget.value) {
        this.customerSelectFormTarget.submit()
        return
      }
    }

    useSelect(this)
    useSpinner(this)
    useActionsBar(this)

    if (this.hasSelectTarget) {
      this.initializeChoices()
      this.connectChoices()
    }
  }

  disconnect() {
    if (this.hasSelectTarget) this.disconnectChoices()
  }

  beforeUpdateFulfillmentMethodId() {
    this.showSpinner(this.fulfillmentMethodSelectSpinTarget, this.fulfillmentMethodSelectContainerTarget)
  }

  afterUpdateFulfillmentMethodId() {
    this.hideSpinner(this.fulfillmentMethodSelectSpinTarget, this.fulfillmentMethodSelectContainerTarget)
  }

  onCustomerSelectChange(event) {
    if (!event.currentTarget.value) return

    this.submitCustomerOrderForm()
  }

  onBrandSelectChange() {
    if (!this.selectTarget.value) { this.selectTarget.click() }
    this.submitCustomerOrderForm()
  }

  onSellerSelectChange(event) {
    if (!event.currentTarget.value) return
    if (!this.hasSellerSelectFormTarget) return

    this.sellerSelectFormTarget.submit()
  }

  onPriceListIdChange() {
    if (confirm('Changing the price list will update the price of all the items currently on the order, are you sure you want to continue?')) {
      this.showSpinner(this.calculatingPriceSpinTarget, this.calculatingPriceContainerTarget)
      this.updatePriceListFormTarget.requestSubmit()
    }
  }

  onWarehouseIdChange() {
    this.showSpinner(this.warehouseSelectSpinTarget, this.warehouseSelectContainerTarget)
    this.updateWarehouseFormTarget.requestSubmit()
  }

  submitCustomerOrderForm() {
    if (!this.selectTarget.value) return
    if (!this.hasCustomerSelectFormTarget) return
    if (this.hasBrandSelectTarget && !this.brandSelectTarget.value) return

    this.customerSelectFormTarget.submit()
  }
}
