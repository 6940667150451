import { useSpinner } from 'controllers/mixins'

export const useTableSpinner = (controller) => {
  return Object.assign(controller, {
    ...useSpinner(controller),
    _showTableSpinner() {
      this.tableSpinnerTargets.forEach((target) => {
        this.showSpinner(this._getSpinTarget(target), this._getSpinContainerTarget(target))
      })
    },

    _hideTableSpinner() {
      this.tableSpinnerTargets.forEach((target) => {
        this.hideSpinner(this._getSpinTarget(target), this._getSpinContainerTarget(target))
      })
    },

    _getSpinTarget(target) {
      return target.querySelector('.spin_component__spinner')
    },
    _getSpinContainerTarget(target) {
      return target.querySelector('.spin_component__container')
    }
  })
}
