import ApplicationController from './application_controller'

export default class extends ApplicationController {

  disableButton(e) {
    const submitButton = e.currentTarget
    if (!submitButton) return

    submitButton.disabled = true
    submitButton.classList.add(...['disabled', 'pointer-events-none'])
    if (submitButton.dataset.disableWith) {
      submitButton.setAttribute('data-label', submitButton.innerText)
      submitButton.innerText = submitButton.getAttribute('data-disable-with') || 'Submitting...'
    }
  }
}
