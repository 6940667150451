import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form', 'sellingByInput']

  connect() {
    super.connect()
  }

  submitForm = (event) => {
    if (this.__areInputsEmpty()) {
      event.preventDefault()
    } else {
      this.formTarget.requestSubmit()
    }
  }

  updateSellingByUI = (event) => {
    this.formTarget.querySelectorAll('span[data-size]').forEach((el) => {
      el.classList.toggle('hidden', event.target.value !== el.dataset.size)
    })
  }

  handleSellingByChange = (event) => {
    this.sellingByInputTarget.value = event.target.value
  }

  __areInputsEmpty = () => {
    for (let [key, value] of new FormData(this.formTarget).entries()) {
      if (key.match(this.quantityInputNameRegEx) && +value) return false
    }

    return true
  }

  get quantityInputNameRegEx() {
    return /orders_line_items_form\[line_items_attributes\]\[([0-9]|[1-9][0-9]|[1-9][0-9][0-9])\]\[quantity\]/g
  }
}
