import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['newVariantForm']

  showNewVariantForm() {
    this.newVariantFormTarget.hidden = false
  }

  hideNewVariantForm() {
    this.newVariantFormTarget.hidden = true
  }
}
