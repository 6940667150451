import Dropzone from 'dropzone'
import { DirectUpload } from '@rails/activestorage'
import Manipulator from 'lib/dom/manipulator'
import DOM from 'lib/dom/dom'

export const useDropzone = (controller) => {
  return Object.assign(controller, {
    createDropZone: function (options = {}) {
      return new Dropzone(this.element, {
        url: this.inputTarget.getAttribute('data-direct-upload-url'),
        headers: { 'X-CSRF-Token': Manipulator.getMetaValue('csrf-token') },
        maxFiles: Manipulator.getDataAttribute(this.element, 'maxFiles') || 1,
        maxFilesize: Manipulator.getDataAttribute(this.element, 'maxFilesize') || 256,
        acceptedFiles: Manipulator.getDataAttribute(this.element, 'acceptedFiles'),
        addRemoveLinks: Manipulator.getDataAttribute(this.element, 'addRemoveLinks'),
        uploadMultiple: Manipulator.getDataAttribute(this.element, 'uploadMultiple') || false,
        autoQueue: false,
        previewTemplate: options.previewTemplate || this.previewTemplate,
        ...options
      })
    }.bind(controller),

    _hideFileInput() {
      this.inputTarget.disabled = true
      this.inputTarget.style.display = 'none'
    },

    _bindEvents() {
      this.dropZone.on('addedfile', this._onAddedFile.bind(this))
      this.dropZone.on('removedfile', this._onRemovedFile.bind(this))
      this.dropZone.on('canceled', this._onCanceled.bind(this))
    },

    _onAddedFile(file) {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
      }, 500)
    },

    _onRemovedFile(file) {
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))

      file.controller && DOM.removeElement(file.controller.hiddenInput)
    },

    _onCanceled(file) {
      file.controller && file.controller.xhr.abort()
    },

    get previewTemplate() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image"><img data-dz-thumbnail /></div>
                <div class="dz-details">\n<div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>\n</div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark">
                  <i class="fad fa-check-circle text-success-dark"></i></i>
                </div>
                <div class="dz-error-mark">
                  <i class="fad fa-times-circle text-danger-dark"></i>
                </div>
              </div>`
    }
  })
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.inputTarget.getAttribute('data-direct-upload-url'), this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        DOM.removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitSourceInputTargetChangeEvent()
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput() {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.source.inputTarget.name
    DOM.insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = (event.loaded / event.total) * 100
    DOM.findElement(this.file.previewTemplate, '.dz-upload').style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit('processing', this.file)

    this.source.element.dispatchEvent(new Event('upload-processing', {bubbles: true}));
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit('error', this.file, error)
    this.source.dropZone.emit('complete', this.file)

    this.source.element.dispatchEvent(new Event('upload-complete', {bubbles: true}));
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit('success', this.file)
    this.source.dropZone.emit('complete', this.file)

    this.source.element.dispatchEvent(new Event('upload-complete', {bubbles: true}));
  }

  emitSourceInputTargetChangeEvent() {
    this.source.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}
