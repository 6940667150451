class ScriptHelper {
  static appendScript(element, callback) {
    const tag = this.createScriptTag(element.dataset.scriptSrc, callback)
    element.prepend(tag)
  }

  static createScriptTag(src, callback) {
    const tag = document.createElement('script')
    tag.type = 'text/javascript'
    tag.src = src
    if (callback) tag.addEventListener('load', callback)
    return tag
  }
}

export default ScriptHelper
