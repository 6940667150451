import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['navItems', 'burger']

  disconnect() {
    if (!this.navItemsTarget.classList.contains('hidden')) this.navItemsTarget.classList.replace('flex', 'hidden')
  }

  toggle(e) {
    e.preventDefault()
    if (this.navItemsTarget.classList.contains('hidden')) {
      this.navItemsTarget.classList.replace('hidden', 'flex')
    } else {
      this.navItemsTarget.classList.replace('flex', 'hidden')
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.navItemsTarget.classList.replace('flex', 'hidden')
    }
  }
}
