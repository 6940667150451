import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['stateSelect', 'priceListSelect', 'pricingGroupSelect']

  change(event) {
    const country_id = event.target.selectedOptions[0].value
    const target = this.stateSelectTarget.id

    let countryUrl = `/countries/states?country_id=${country_id}&target=${target}`

    if (this.hasPriceListSelectTarget) {
      countryUrl += `&price_list_target=${this.priceListSelectTarget.id}`
    }

    if (this.hasPricingGroupSelectTarget) {
      countryUrl += `&pricing_group_target=${this.pricingGroupSelectTarget.id}`
    }

    get(countryUrl, {
      responseKind: 'turbo-stream'
    })
  }
}
