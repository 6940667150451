import omit from 'lodash/omit'

export const useSelectedProductRow = (controller) => {
  return Object.assign(controller, {
    _findCurrentRowIndex(target) {
      const currentRowTarget = this._getClosestRow(target)
      if (!currentRowTarget) return -1

      return this.visibleFilterableTargets().findIndex((t) => t.id === currentRowTarget.id)
    },

    _getClosestRow(element) {
      return element.closest('tr')
    },

    _addSelectedRow(row) {
      if (this.selectedRows[row.id]) return

      const rowTargetIndex = this.filterableTargets.findIndex((el) => el.id === row.id)

      if (rowTargetIndex >= 0) {
        this.selectedRows = { ...this.selectedRows, [row.id]: rowTargetIndex }
        this._updateIndicator()
      }
    },

    _removeSelectedRow(row) {
      this.selectedRows = omit(this.selectedRows, row.id)
      this._updateIndicator()
    },

    _updateIndicator() {
      if (Object.keys(this.selectedRows).length) {
        this.iconTarget.classList.add('hidden')
        this.indicatorTarget.innerHTML = Object.keys(this.selectedRows).length
        this.indicatorTarget.classList.remove('hidden')
      } else {
        this.iconTarget.classList.remove('hidden')
        this.indicatorTarget.classList.add('hidden')
      }
    },

    _clearSelectedRows() {
      Object.keys(this.selectedRows).forEach((rowId) => {
        this.filterableTargets[this.selectedRows[rowId]]?.setAttribute('data-selected', false)
      })
    }
  })
}
