// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/activestorage').start()
require('channels')
require('form-request-submit-polyfill')
require('lib/timezone')

import '@hotwired/turbo-rails'
import 'config/initializers'
import 'controllers'
import 'stylesheets/application.css'
import DOM from 'lib/dom/dom'

// polyfills
import 'web-animations-js'

import './pagy'

if (!window.scrollPositions) {
  window.scrollPositions = {};
}

function preserveScroll () {
  document.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    scrollPositions[element.id] = element.scrollTop;
  })
}

function restoreScroll () {
  document.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    element.scrollTop = scrollPositions[element.id];
  })
}

window.addEventListener("turbo:before-cache", preserveScroll)
window.addEventListener("turbo:before-render", restoreScroll)
window.addEventListener("turbo:render", restoreScroll)

if (DOM.isMobile()) {
  window.addEventListener('click', function(e) {
    if (e.target !== document.querySelector('#app-header-open') && e.target !== document.querySelector('#switch') && e.target !== document.querySelector('#mobile-sort')) {
      const hiddenElements = ["#full-nav", "#mobile-menu-close-button"]
      const shownElements = ["#app-header-open", "#mobile-collapsed-open"]

      hiddenElements.forEach((element) => {
        let el = document.querySelector(element)
        if (el) {
          el.classList.add("hidden")
        }
      })

      shownElements.forEach((element) => {
        let el = document.querySelector(element)
        if (el) {
          el.classList.remove("hidden")
        }
      })

      if (document.querySelector('#app-content') != null) {
        document.querySelector('#app-content').setAttribute('data-sidebar-visible', false)
        document.querySelector('#main-content').classList.remove('pointer-events-none')
        document.querySelector('#main-content').animate([{ left: '260px', left: '0px' }], { duration: 100, fill: 'forwards', easing: 'ease-in' })
      }
      if (document.querySelector('#app-index-content') != null) {
        document.querySelector('#app-index-content').setAttribute('data-sidebar-visible', false)
        document.querySelector('#main-index-content').classList.remove('pointer-events-none')
        document.querySelector('#main-index-content').animate([{ left: '260px', left: '0px' }], { duration: 100, fill: 'forwards', easing: 'ease-in' })
      }

    }
  });
}
