import ApplicationController from './application_controller'
import {
  useFilterable,
  useSpinner,
  useSelectSellingBy,
  useQuantityInput,
  useSelectedProductRow,
  useLockScroll
} from './mixins'
import DOMEvents from 'lib/dom/events'
import DOMHelper from 'lib/dom/dom'
import WebAnimation from 'lib/animation'

export default class extends ApplicationController {
  static values = {
    reflexAction: { type: String, default: 'update_or_create' }
  }
  static targets = [
    'searchInput',
    'filterable',
    'listbox',
    'quantityInput',
    'priceInput',
    'saveButton',
    'cancelButton',
    'indicator',
    'icon',
    'spin',
    'productQuantityInput',
    'batchSelectSellingBy'
  ]

  initialize() {
    super.initialize()
  }

  connect() {
    useFilterable(this)
    useSpinner(this)
    useSelectSellingBy(this)
    useQuantityInput(this)
    useSelectedProductRow(this)
    useLockScroll(this)
    super.connect()

    this.scrollPosition = null
    this.selectedRows = {} // { domId: targetIndex }
    this.formsValid = true

    if (this.searchInputTarget.autofocus) {
      this.showListbox()
    }
  }

  disconnect() {
    super.disconnect()

    this._clearInputs()
    this.selectedRows = {}
  }

  beforeCreate() {
    this.beforeUpdateOrCreate()
  }

  afterCreate() {
    this.afterUpdateOrCreate()
  }

  beforeUpdateOrCreate() {
    this.showSpinner()
    this.hideListbox()
    this.searchInputTarget.disabled = true
  }

  afterUpdateOrCreate() {
    this.hideSpinner()
    this.searchInputTarget.disabled = false
  }

  createSuccess() {
    this.updateOrCreateSuccess()
  }

  updateOrCreateSuccess() {
    this._clearInputs()
    this.resetList()
    this._clearSelectedRows()
    this.selectedRows = {}
    this._updateIndicator()
  }

  createError() {
    this.updateOrCreateError()
  }

  updateOrCreateError() {
    this.hideSpinner()
  }

  showListbox() {
    if (this.show) return

    WebAnimation.enter(this.listboxTarget, {
      keyframes: {
        opacity: [0, 1],
        transform: ['scale(0.95)', 'scale(1)']
      },
      options: { duration: 100, easing: 'ease-out', fill: 'forwards' },
      onfinish: () => {
        if (DOMHelper.isMobile()) {
          this.lockScroll()
        } else {
          this.listboxTarget.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    })
    this.searchInputTarget.setAttribute('aria-expanded', true)
    this.element.setAttribute('data-expanded', true)
  }

  hideListbox() {
    if (!this.show) return

    WebAnimation.leave(this.listboxTarget, {
      keyframes: {
        opacity: [1, 0],
        transform: ['scale(1)', 'scale(0.95)']
      },
      options: { duration: 100, easing: 'ease-out', fill: 'forwards' }
    })
    this.searchInputTarget.setAttribute('aria-expanded', false)
    this.element.setAttribute('data-expanded', false)

    if (DOMHelper.isMobile()) {
      this.unlockScroll()
    }
  }

  onSearchInputKeyPress(event) {
    switch (event.keyCode) {
      case DOMEvents.keyCodes.ESC:
        if (this.show) this.hideListbox()
        break
      case DOMEvents.keyCodes.RETURN:
        this.submit()
        break
      case DOMEvents.keyCodes.SPACE:
        if (!this.show) this.showListbox()
        break
      default:
        if (!this.show) this.showListbox()
        break
    }
  }

  onPriceInputKeyPress(event) {
    switch (event.keyCode) {
      case DOMEvents.keyCodes.ESC:
        if (this.show) this.hideListbox()
        break
      case DOMEvents.keyCodes.LEFT:
        if (event.shiftKey) {
          event.preventDefault()
          const currentRowIndex = this._findCurrentRowIndex(event.target)
          this._focusSiblingQuantityInput(currentRowIndex)
        }
        break
      case DOMEvents.keyCodes.RETURN:
        this.submit()
        break
    }
  }

  onClickOutside(event) {
    const controller = event.target.closest(
      `[data-controller="${this.element.dataset.controller}"]`
    )
    if (this.element === controller) {
      return
    }

    if (this.show) this.hideListbox()
  }

  onSearchInputChange(event) {
    this.initFilterable()
    this.search(event.target.value)
  }

  checkFormsValidity() {
    this.formsValid = this._checkAllFormAreValid()

    if (!this.formsValid) {
      this.saveButtonTarget.disabled = true
    } else {
      this.saveButtonTarget.disabled = false
    }
  }

  submit(e) {
    e?.preventDefault()
    if (!this.formsValid) return

    const formParams = Object.keys(this.selectedRows).reduce((acc, rowId) => {
      const rowTarget = this.filterableTargets[this.selectedRows[rowId]]
      const productVariantId = rowTarget.getAttribute('data-variant-id')
      const productId = rowTarget.getAttribute('data-product-id')

      const quantityForm = this.element.querySelector(`#form-product-quantity-${rowId}`)
      const quantityFormData = new FormData(quantityForm)

      acc.push({
        liquid_variant_id: productVariantId,
        product_id: productId,
        line_item: {
          quantity: parseInt(quantityFormData.get('quantity').replace(',', '')),
          selling_by: quantityFormData.get('selling-by'),
          increment_quantity: true
        }
      })

      return acc
    }, [])

    this.stimulate(
      `${this.element.getAttribute('data-reflex-name')}#${this.reflexActionValue}`,
      this.element,
      formParams
    )
  }

  _focusSiblingPriceInput(currentRowNumber) {
    const currentRowTargetIndex = this.filterableTargets.findIndex(
      (t) => t.id === this.visibleFilterableTargets()[currentRowNumber].id
    )
    this.priceInputTargets[currentRowTargetIndex].focus()
  }

  _clearInputs() {
    this.quantityInputTargets.forEach((input) => (input.value = null))
    this.searchInputTarget.value = ''
  }

  _checkAllFormAreValid() {
    for (const form of this.forms) {
      if (!form.checkValidity()) return false
    }

    return true
  }

  get itemsCount() {
    return this.visibleFilterableTargets().length
  }

  get sellingBy() {
    return this.batchSelectSellingByTarget.value
  }

  get show() {
    return !this.listboxTarget.classList.contains('hidden')
  }

  get forms() {
    return this.listboxTarget.querySelectorAll('form')
  }
}
