import ApplicationController from './application_controller'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends ApplicationController {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end,
      animation: 150
    })
  }

  end = (event) => {
    let id = event.item.dataset.id
    let data = new FormData()
    let paramsKey = this.element.dataset.paramsKey
    data.append(`${paramsKey}`, event.newIndex)

    Rails.ajax({
      url: this.data.get('url').replace(':id', id),
      type: 'PATCH',
      data: data
    })
  }
}
