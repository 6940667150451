import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

export const useSearch = (controller) => {
  Object.assign(controller, {
    search(event) {
      event.preventDefault()
      const reflex = upperFirst(camelCase(this.identifier))

      this.selectors?.length
        ? this.stimulate(`${reflex}#search`, event.target, { selectors: this.selectors })
        : this.stimulate(`${reflex}#search`, event.target)
    },
    clearSearch() {
      if (this.searchTarget.value === '') return

      this.searchTarget.value = ''
      this.searchTarget.dispatchEvent(new Event('input', { bubbles: true }))
    }
  })
}
