import ApplicationController from './application_controller'
import { useSpreedly } from './mixins/useSpreedly'

export default class extends ApplicationController {
  static targets = ['submitButton', 'termsAndConditionsInput']

  connect() {
    super.connect()
    useSpreedly(this)
    this.initSpreedly()
  }

  submitForm(event) {
    event.preventDefault()
    event.stopPropagation()
    this.submitSpreedly()
  }

  togglePreauthorization(event) {
    let terms = (event.target.value === 'true') ? I18n.payment_sources.pre_auth_terms_and_conditions_html : I18n.payment_sources.terms_and_conditions_html
    let parser = new DOMParser()
    let html_terms = parser.parseFromString(terms, 'text/html').body.childNodes
    let checkbox = this.termsAndConditionsInputTarget.parentElement.firstElementChild
    this.termsAndConditionsInputTarget.parentElement.replaceChildren(checkbox, ...html_terms)
    this.termsAndConditionsInputTarget.checked = false
  }
}
