import ApplicationController from './application_controller'
import { useExpandable } from 'controllers/mixins/useExpandable'

const SCROLL_POSITION = {
  START: 'start',
  SCROLLING: 'SCROLLING',
  END: 'end'
}

export default class extends ApplicationController {
  static targets = ['expandableTrigger', 'expandablePanel', 'scrollContainer']
  static values = { scrollable: Boolean }

  connect() {
    this.state = {
      scrollPosition: SCROLL_POSITION.DEFAULT
    }
    useExpandable(this)

    if (this.scrollableValue) {
      this.checkScrollPosition()
      this.scrollContainerTarget.addEventListener('scroll', this.checkScrollPosition.bind(this))
    }
  }

  disconnect() {
    if (this.scrollableValue) {
      this.scrollContainerTarget.removeEventListener('scroll', this.checkScrollPosition)
    }
  }

  checkScrollPosition() {
    if (
      this.scrollContainerTarget.offsetWidth + this.scrollContainerTarget.scrollLeft >=
      this.scrollContainerTarget.scrollWidth
    ) {
      this.onScrollEnd()
    } else if (this.scrollContainerTarget.scrollLeft === 0) {
      this.onScrollStart()
    } else this.onScroll()
  }

  onScrollEnd() {
    if (this.state.position === SCROLL_POSITION.END) return

    this.state = { ...this.state, position: SCROLL_POSITION.END }
    this.element.classList.add('table-component__ping--left')
    this.element.classList.remove('table-component__ping--right')
  }

  onScrollStart() {
    if (this.state.position === SCROLL_POSITION.START) return

    this.state = { ...this.state, position: SCROLL_POSITION.START }
    this.element.classList.remove('table-component__ping--left')
    this.element.classList.add('table-component__ping--right')
  }

  onScroll() {
    if (this.state.position === SCROLL_POSITION.SCROLLING) return

    this.state = { ...this.state, position: SCROLL_POSITION.SCROLLING }
    this.element.classList.add('table-component__ping--right')
    this.element.classList.add('table-component__ping--left')
  }
}
