import ApplicationController from './application_controller'
import Session from 'lib/dom/session'

export default class extends ApplicationController {
  static targets = ['btnMapView', 'btnListView']

  connect() {
    super.connect()
    this.views = Object.freeze({ maps: 'MAPS', list: 'LIST' })
    this.activeView === this.views.list ? this.off() : this.on()
  }

  on() {
    if (this.hidden && this.mapTarget) this.mapTarget.classList.remove('hidden')

    if (this.hasBtnMapViewTarget) {
      this.btnMapViewTarget.classList.add('text-primary')
    }
    this.btnListViewTarget.classList.remove('text-primary')
    this.activeView = this.views.maps
  }

  off() {
    if (this.hidden) {
      return
    }

    this.mapTarget.classList.add('hidden')
    if (this.hasBtnMapViewTarget) {
      this.btnMapViewTarget.classList.remove('text-primary')
    }
    this.btnListViewTarget.classList.add('text-primary')
    this.activeView = this.views.list
  }

  get hidden() {
    if (!this.mapTarget) return true
    return this.mapTarget.classList.contains('hidden')
  }

  get mapTarget() {
    return document.querySelector('div[data-map-buttons-target="map"]')
  }

  get activeView() {
    return Session.getSessionStorage(this.sessionKey) || this.views.list
  }

  set activeView(value) {
    Session.setSessionStorage(this.sessionKey, value)
  }

  get sessionActiveView() {
    return `${window.location.pathname}-view`
  }
}
