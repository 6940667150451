var Color = require('color')

const colors = {
  background: {
    base: '#FDFDFD',
    surface: '#FDFDFD',
    'surface-subdued': '#fafafb',
    input: '#fafafb'
  },
  white: '#fff',
  black: '#000',
  transparent: 'transparent',
  current: 'currentColor',
  gray: {
    50: '#fcfbfc',
    75: '#F4F4F5',
    100: '#f0f0f1',
    200: '#f3f3f4',
    300: '#e5e5e7',
    400: '#d2d2d5',
    500: '#babac0',
    600: '#94949d',
    700: '#73737c',
    800: '#505058',
    900: '#313136'
  },
  red: {
    100: '#f7eceb',
    200: '#edb2ad',
    300: '#e7847d',
    400: '#de4b48',
    500: '#d12524',
    600: '#af1413',
    700: '#99100e'
  },
  orange: {
    50: '#fff5e6',
    100: '#fae8d1',
    150: '#fee1b1',
    200: '#fdc37b',
    250: '#fc9e03',
    300: '#fb953c',
    400: '#f06e10',
    500: '#d2520d',
    600: '#b34311',
    700: '#943d18'
  },
  yellow: {
    100: '#f7efc6',
    200: '#f9d34a',
    300: '#eab408',
    400: '#c88804',
    500: '#a76807',
    600: '#8d5811',
    700: '#7b4e16'
  },
  purple: {
    100: '#f2ecf7',
    200: '#dfcaf1',
    300: '#d0a2f7',
    400: '#b37ce5',
    500: '#9955d8',
    600: '#7c2abe',
    700: '#631f9a'
  },
  blue: {
    100: '#e1f0f9',
    200: '#a7d9f8',
    300: '#77bde7',
    400: '#489ccf',
    500: '#347daa',
    550: '#3c6486',
    600: '#2f678a',
    700: '#285571',
    750: '#2d4b65',
    800: '#163141',
    900: '#13232e'
  },
  green: {
    100: '#f6faf6',
    200: '#89e781',
    300: '#5ecb55',
    400: '#3fa936',
    500: '#2f8727',
    600: '#25731e',
    700: '#165810'
  },
  emerald: {
    100: '#d1fae5',
    200: '#a4f0d0',
    300: '#6ee6b8',
    400: '#34d399',
    500: '#10b981',
    600: '#06976d',
    700: '#05795a'
  },
  neutrals: {
    50: '#f5f5f5'
  }
}

const darkColors = {
  'dark-mode-background': {
    base: '#262529',
    surface: '#2c2c31',
    'surface-subdued': '#262529'
  },
  'dark-mode-gray': {
    50: '#1c1b1e',
    75: '#222225',
    100: '#262529',
    200: '#2c2c31',
    300: '#35353a',
    400: '#404048',
    500: '#505059',
    600: '#6d6d77',
    700: '#8e8e97',
    800: '#bbbbc1',
    900: '#ebebec'
  },
  'dark-mode-red': {
    100: '#51120c',
    200: '#9b100f',
    300: '#c71717',
    400: '#df544f',
    500: '#e5766e',
    600: '#ea9d96',
    700: '#edb4ae'
  },
  'dark-mode-orange': {
    100: '#402114',
    200: '#6e3319',
    300: '#973e17',
    400: '#c1450c',
    500: '#e25c0c',
    600: '#f77914',
    700: '#fb9942'
  },
  'dark-mode-yellow': {
    100: '#362613',
    200: '#553917',
    300: '#6e4817',
    400: '#9d6009',
    500: '#bd7e06',
    600: '#d39605',
    700: '#e1a807'
  },
  'dark-mode-purple': {
    100: '#32194c',
    200: '#5e1c94',
    300: '#7829b8',
    400: '#9348d5',
    500: '#ab71e1',
    600: '#cd9ef5',
    700: '#dbc3f2'
  },
  'dark-mode-blue': {
    100: '#173243',
    200: '#21455b',
    300: '#2a5977',
    400: '#33759e',
    500: '#3a93c9',
    600: '#5fabdb',
    700: '#82c4ec',
    800: '#cde3f0'
  },
  'dark-mode-green': {
    100: '#10370b',
    200: '#0e4d09',
    300: '#1b6215',
    400: '#2b7f23',
    500: '#3a9f32',
    600: '#45b63c',
    700: '#72d869'
  }
}

const palette = {
  primary: {
    DEFAULT: colors.orange['400'],
    hover: colors.orange['500'],
    active: colors.orange['600'],
    disabled: Color(colors.orange['400']).opaquer(0.5)
  },
  neutral: {
    bg: colors.gray['100'],
    text: colors.gray['800'],
    'text-dark': colors.gray['900']
  },
  info: {
    bg: colors.blue['100'],
    text: colors.blue['400'],
    'text-dark': colors.blue['600']
  },
  danger: {
    DEFAULT: colors.red['400'],
    bg: colors.red['100'],
    text: colors.red['400'],
    'text-dark': colors.red['600']
  },
  success: {
    DEFAULT: colors.green['400'],
    bg: colors.green['100'],
    text: colors.green['400'],
    'text-dark': colors.green['600']
  },
  warning: {
    bg: colors.yellow['100'],
    text: colors.yellow['400'],
    'text-dark': colors.yellow['600']
  },
  alert: {
    bg: colors.purple['100'],
    text: colors.purple['400'],
    'text-dark': colors.purple['600']
  },
  secondary: {
    DEFAULT: colors.gray['50'],
    hover: colors.gray['75'],
    active: colors.gray['100'],
    disabled: colors.gray['200']
  },
  interactive: {
    DEFAULT: colors.blue['400'],
    hover: colors.blue['500'],
    active: colors.blue['600'],
    disabled: Color(colors.blue['400']).opaquer(0.5),
    bg: colors.blue['100']
  }
}

const textColor = {
  disabled: colors.gray['500'],
  placeholder: colors.gray['600'],
  default: colors.gray['800'],
  light: colors.gray['700'],
  dark: colors.gray['900'],
  inverted: colors.gray['50'],
  'dark-mode-disabled': darkColors['dark-mode-gray']['600'],
  'dark-mode-placeholder': darkColors['dark-mode-gray']['700'],
  'dark-mode-default': darkColors['dark-mode-gray']['900'],
  'dark-mode-light': darkColors['dark-mode-gray']['800'],
  'dark-mode-dark': darkColors['dark-mode-gray']['900'],
  'dark-modeinverted': darkColors['dark-mode-gray']['50']
}

module.exports = {
  colors,
  palette,
  textColor,
  darkColors
}
