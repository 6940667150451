import DOM from './dom'

class Manipulator {
  static setDataAttribute(element, key, value) {
    return this.setAttribute('data')(element, key, value)
  }

  static removeDataAttribute(element, key) {
    return this.removeAttribute('data')(element, key)
  }

  static getDataAttribute(element, key) {
    return this.getAttribute('data')(element, key)
  }

  static getDataAttributes(element) {
    if (!element) return {}

    return Object.keys(element.dataset).reduce((acc, curr) => {
      acc[curr] = normalizedValue(element.dataset[curr])
      return acc
    }, {})
  }

  static setAriaAttribute(element, key, value) {
    return this.setAttribute('aria')(element, key, value)
  }

  static removeAriaAttribute(element, key) {
    return this.removeAttribute('aria')(element, key)
  }

  static getAriaAttribute(element, key) {
    return this.getAttribute('aria')(element, key)
  }

  static position(element) {
    return {
      top: element.offsetTop,
      left: element.offsetLeft
    }
  }

  static scrollRight(element) {
    return element.scrollWidth - (element.scrollLeft + element.clientWidth) + 1
  }

  static toggleClass(element, classNames) {
    if (!element) return

    typeof classNames === Array
      ? classNames.forEach((c) => {
          element.classList.toggle(c)
        })
      : element.classList.toggle(classNames)
  }

  static getMetaValue(name) {
    const element = DOM.findElement(document.head, `meta[name="${name}"]`)
    if (element) {
      return element.getAttribute('content')
    }
  }

  static getAttribute(type) {
    return (element, key) => {
      if (!element) return

      return normalizedValue(element.getAttribute(`${type}-${normalizeDataKey(key)}`))
    }
  }

  static setAttribute(type) {
    return (element, key, value) => {
      if (!element) return

      element.setAttribute(`${type}-${normalizeDataKey(key)}`, value)
    }
  }

  static removeAttribute(type) {
    return (element, key) => {
      if (!element) return

      element.removeAttribute(`${type}-${normalizeDataKey(key)}`)
    }
  }
}

function normalizeDataKey(key) {
  return key.replace(/[A-Z]/g, (chr) => `-${chr.toLowerCase()}`)
}

function normalizedValue(value) {
  switch (value) {
    case null:
    case undefined:
    case '':
      return null
    case 'false':
      return false
    case 'true':
      return true
  }

  try {
    return JSON.parse(value)
  } catch (err) {
    return value
  }
}

export default Manipulator
