import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['submitButton', 'checkboxInput', 'acceptTermsWarning', 'terms', 'viewMore', 'viewLess']

  connect() {}

  toggleSubmitButton(_event) {
    this.checkboxInputTarget.checked ? this.enableSubmitButton() : this.disableSubmitButton()
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  viewTerms(e) {
    e.preventDefault()
    this.termsTarget.classList.remove('hidden')
    this.viewMoreTarget.classList.add('hidden')
    this.viewLessTarget.classList.remove('hidden')
  }

  hideTerms(e) {
    e.preventDefault()
    this.termsTarget.classList.add('hidden')
    this.viewLessTarget.classList.add('hidden')
    this.viewMoreTarget.classList.remove('hidden')
  }
}
