import ApplicationController from './application_controller'
import { useTransitionedRow } from './mixins'

export default class extends ApplicationController {
  connect() {
    useTransitionedRow(this)
    super.connect()
  }

  afterToggle(element) {
    this.animateRow(element)
  }
}
