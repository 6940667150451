import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['termsAndConditions', 'termsSubmitButton', 'termsForm']

  connect() {
    const { checked } = this.termsAndConditionsTarget
    this.termsSubmitButtonTarget.disabled = !checked
  }

  toggleSubmitButtons({ target: { checked } }) {
    this.termsSubmitButtonTarget.disabled = !checked
  }

  submitForm() {
    if(this.hasTermsFormTarget) { this.termsFormTarget.submit() }
  }
}
