import ApplicationController from './application_controller'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = [
    'reportRansackForm',
    'spin',
    'container'
  ]

  connect() {
    super.connect()
    useSpinner(this)
  }

  update() {
    this.submitForm()
  }

  submitForm() {
    this.showSpinner()
    this.reportRansackFormTarget.requestSubmit()
  }
}
