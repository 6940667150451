import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['link']

  connect() {
    super.connect()
  }

  scrollTop(event) {
    if (document.body.contains(document.getElementById('top'))) {
      return // Use an explicit anchor point and reload the page
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    event.preventDefault()
  }

  toggleVisibility() {
    if (window.scrollY >= this.scrollYTrigger()) {
      this.linkTarget.classList.remove('hidden')
    } else {
      this.linkTarget.classList.add('hidden')
    }
  }

  scrollYTrigger() {
    return window.innerHeight / 4 // quarter of the viewport height
  }
}
