import Manipulator from 'lib/dom/manipulator'

export const useOverflowNavigation = (controller) => {
  return Object.assign(controller, {
    _scrollLeft(overflowContainer = this.tabsContainerTarget) {
      if (!overflowContainer.scrollLeft) return
      const scrollAmount =
        overflowContainer.scrollLeft < overflowContainer.clientWidth
          ? overflowContainer.scrollLeft
          : overflowContainer.clientWidth

      overflowContainer.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
    },

    _scrollRight(overflowContainer = this.tabsContainerTarget) {
      if (!Manipulator.scrollRight(overflowContainer)) return

      const scrollAmount =
        Manipulator.scrollRight(overflowContainer) < overflowContainer.clientWidth
          ? Manipulator.scrollRight(overflowContainer)
          : overflowContainer.clientWidth

      overflowContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    },

    _scrollToTab(element) {
      element.parentNode.scrollLeft = element.offsetLeft - element.parentNode.offsetLeft
    }
  })
}
