import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['modal', 'search']

  connect() {}

  clearSearch = (event) => {
    this.searchTarget.value = ''

    this.searchTarget.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true
      })
    )
  }
}
