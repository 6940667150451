import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["checkbox"]

  toggleAll(event) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
      checkbox.dispatchEvent(new Event("change"));
   })
  }
}
