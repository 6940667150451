import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['brand', 'alert', 'link']
  static values = { missing: Array, seller: Number }

  connect() {
    this.toggleAlert(this.brandTarget.value)
  }

  select(event) {
    this.toggleAlert(event.target.value)
  }

  toggleAlert(brandId) {
    const isMissing = this.missingValue.includes(Number.parseInt(brandId))

    if(isMissing) {
      this.linkTarget.href = `/sellers/${this.sellerValue}/brands/${brandId}/edit#personalized_communications`
      this.alertTarget.classList.remove('hidden')
    } else {
      this.alertTarget.classList.add('hidden')
    }
  }
}
