import Dropzone from 'dropzone'
import ApplicationController from './application_controller'
import { useDropzone } from './mixins/useDropzone'

export default class extends ApplicationController {
  static targets = ['input', 'removeButton', 'icon']

  connect() {
    useDropzone(this)
    this.dropZone = this.createDropZone({
      previewTemplate: this._previewTemplate()
    })
    this._hideFileInput()
    this._bindEvents()
    Dropzone.autoDiscover = false

    this.dropZone.on('addedfile', this.handleAddedFile.bind(this))
    this.dropZone.on('removedfile', this.removeDeleteFileAction.bind(this))
    this.dropZone.on('complete', () => {
      this.removeButtonTarget.disabled = false
    })
  }

  removeFiles(e) {
    e.preventDefault()
    this.dropZone.removeAllFiles()
  }

  handleAddedFile() {
    if (this.dropZone.files.length > 1) {
      const previousImage = this.dropZone.files[0]
      this.dropZone.emit('removedfile', previousImage)
    }
    this._showDeleteFileAction()
  }

  removeDeleteFileAction() {
    const animation1 = this.removeButtonTarget.animate(this.leaveAnimation.keyframes, this.leaveAnimation.options)
    const animation2 = this.iconTarget.animate(this.enterAnimation.keyframes, this.enterAnimation.options)
    animation2.pause()

    animation1.onfinish = () => {
      this._hideRemoveButton()
      this._showIcon()
      animation2.play()
    }
  }

  _showDeleteFileAction() {
    this.removeButtonTarget.disabled = true
    const animation1 = this.iconTarget.animate(this.leaveAnimation.keyframes, this.leaveAnimation.options)
    const animation2 = this.removeButtonTarget.animate(this.enterAnimation.keyframes, this.enterAnimation.options)
    animation2.pause()

    animation1.onfinish = () => {
      this._hideIcon()
      this._showRemoveButton()
      animation2.play()
    }
  }

  _showRemoveButton() {
    this.removeButtonTarget.classList.remove('hidden')
  }

  _hideRemoveButton() {
    this.removeButtonTarget.classList.add('hidden')
  }

  _showIcon() {
    this.iconTarget.classList.remove('hidden')
  }

  _hideIcon() {
    this.iconTarget.classList.add('hidden')
  }

  _previewTemplate() {
    return `<div class="dz-preview dz-file-preview">
              <div class="dz-image editable_avatar__preview_image"><img data-dz-thumbnail /></div>
              <div class="dz-details">\n<div class="dz-size"><span data-dz-size></span></div>
              <div class="dz-filename"><span data-dz-name></span></div>
              </div>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
              <div class="dz-error-message"><span data-dz-errormessage></span></div>
              <div class="dz-success-mark">\n<i class="fad fa-check-circle text-success-dark"></i></i>
              </div>
              <div class="dz-error-mark">
                <i class="fad fa-times-circle text-danger-dark"></i>
              </div>
            </div>`
  }

  get enterAnimation() {
    return {
      keyframes: {
        opacity: ['0', '1']
      },
      options: { duration: 200, easing: 'ease-out', fill: 'forwards' }
    }
  }

  get leaveAnimation() {
    return {
      keyframes: {
        opacity: ['1', '0']
      },
      options: { duration: 150, easing: 'ease-in', fill: 'forwards' }
    }
  }
}
