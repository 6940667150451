import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {}

  updateRadioInputs(event) {
    if (!event.currentTarget.dataset.ransackQuery) { return }

    let name = event.currentTarget.querySelector("input").name

    document.querySelectorAll(`input[name='${name}']`).forEach((node) => {
      node.name = `q[${event.currentTarget.dataset.ransackQuery}]`
    })
  }
}
