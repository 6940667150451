/* eslint-disable no-undef */
import ApplicationController from './application_controller'
import Manipulator from '../lib/dom/manipulator'
import { Loader } from "@googlemaps/js-api-loader"

export default class extends ApplicationController {
  static targets = ['map', 'markers']
  static values = { key: String }

  mapTargetConnected(_target) {
    if (!this.keyValue) { return }

    const loader = new Loader({
      apiKey: this.keyValue,
      version: "weekly",
      loading: "async"
    });

    loader.importLibrary('maps').then(async () => {
      this.setupMap()
    }).catch((error) => {
      console.error(error)
    })
  }

  async setupMap() {
    super.connect()
    this.mapId = Manipulator.getDataAttribute(this.mapTarget, 'mapId')
    this.zoom = Manipulator.getDataAttribute(this.mapTarget, 'zoom')
    this.points = JSON.parse(this.mapTarget.getAttribute('data-markers')).filter(n => n)
    if (!this.points.length) { return }
    this.center = Manipulator.getDataAttribute(this.mapTarget, 'center') || this.createCenter()
    this.markers = []

    if (window['google']) {
      const { Map } = await google.maps.importLibrary("maps");

      this.bounds = new google.maps.LatLngBounds()

      this.map = new Map(document.getElementById(this.mapId), {
        zoom: this.zoom,
        center: this.center,
        mapId: this.mapId
      })
      await this.setupMarkers()

      google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        if (this.markers.length > 1) {
          this.map.fitBounds(this.bounds)
        }
      })
    }
  }

  async setupMarkers() {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    if (this.points.length) {
      this.points.forEach((point) => {
        const marker = new AdvancedMarkerElement({
          position: { lat: point.lat, lng: point.lng },
          map: this.map
        })
        this.bounds.extend(marker.position)
        this.markers.push(marker)

        if (point.content) {
          const infowindow = this.createInfoWindow(point.content)
          marker.addListener('click', () => {
            infowindow.open(this.map, marker)
          })
        }
      })
    }
  }

  createCenter() {
    if (this.points.length) return { lat: this.points[0].lat, lng: this.points[0].lng }

    return this.defaultCenter
  }

  createIconMarker(logoUrl, content) {
    if (logoUrl) {
      return (
        'data:image/svg+xml;charset=UTF-8;base64,' +
        btoa(`
        <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
              <path d="M6.38265 20.02L14.9904 32C16.7062 29.6571 20.7607 24.087 23.2522 20.5505C25.7437 17.014 26.107 13.6248 25.9773 12.3723C25.3198 5.90043 20.2243 2.98575 17.7588 2.33738C15.1779 1.61534 9.15963 1.57703 5.73382 7.20011C2.30801 12.8232 4.73895 18.0897 6.38265 20.02Z" fill="white"/>
            </g>
            <rect x="8" y="5" width="14" height="14" fill="url(#pattern0)"/>
            <defs>
            <filter id="filter0_d" x="0" y="0" width="30" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0" transform="scale(0.01)"/>
            </pattern>
            <image id="image0" width="100" height="100" xlink:href="https://images.pexels.com/photos/1552630/pexels-photo-1552630.jpeg?cs=srgb&dl=pexels-engin-akyurt-1552630.jpg&fm=jpg" />
            </defs>
          </svg>`)
      )
    } else {
      return (
        'data:image/svg+xml;charset=UTF-8;base64,' +
        btoa(
          `<svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
              <path d="M6.38265 20.02L14.9904 32C16.7062 29.6571 20.7607 24.087 23.2522 20.5505C25.7437 17.014 26.107 13.6248 25.9773 12.3723C25.3198 5.90043 20.2243 2.98575 17.7588 2.33738C15.1779 1.61534 9.15963 1.57703 5.73382 7.20011C2.30801 12.8232 4.73895 18.0897 6.38265 20.02Z" fill="white"/>
            </g>
            <path fill="currentColor" d="M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-29.6 47.2-10 110.6 38 130.8v227.4c0 19.4 14.3 35.2 32 35.2h448c17.7 0 32-15.8 32-35.2V249.4c48-20.2 67.6-83.6 38-130.8zM516 464H100v-96h416zm-.2-144.2H100v-64.7c24-3.3 45.1-15.2 60.3-32.2 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 15.3 17 36.3 28.9 60.3 32.2zm47.9-133c-3.2 6.8-10.9 18.6-27 20.8-2.4.3-4.8.5-7.2.5-14.7 0-28.2-6.1-38.1-17.2L455.7 151 420 190.8c-9.9 11.1-23.5 17.2-38.1 17.2s-28.2-6.1-38.1-17.2L308 151l-35.7 39.8c-9.9 11.1-23.5 17.2-38.1 17.2-14.7 0-28.2-6.1-38.1-17.2L160.3 151l-35.7 39.8c-9.9 11.1-23.5 17.2-38.1 17.2-2.5 0-4.9-.2-7.2-.5-16-2.2-23.8-13.9-27-20.8-5-10.8-7.1-27.6 2.3-42.6L114.8 48h386.3l60.2 96.1c9.5 15.1 7.5 31.9 2.4 42.7z" class=""></path>
            <defs>
              <filter id="filter0_d" x="0" y="0" width="30" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
            </defs>
          </svg>`
        )
      )
    }
  }

  createInfoWindow(content) {
    const contentString = `<div class="font-semibold font-lg">${content}</div>`

    return new google.maps.InfoWindow({
      content: contentString
    })
  }

  get defaultCenter() {
    return { lat: 49.259955, lng: -123.113949 }
  }
}
