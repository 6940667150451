export const useEditableForm = (controller) => {
  controller.toggleClasses = ['opacity-50', 'cursor-not-allowed']
  Object.assign(controller, {
    addAttrsToForm() {
      const form = this.element.querySelector('form')
      if (form) {
        form.id = this.element.dataset.statefulId || this.element.id
        form.dataset.target = `${this.identifier}.form`
        form.dataset.action = `turbo:submit-end->${this.identifier}#onSubmitSuccess ajax:error->${this.identifier}#onSubmitError`

        this.formInputs().forEach((input) => {
          input.dataset.action += ` debounced:input->${this.identifier}#input debounced:change->${this.identifier}#input`
        })

        if (this.formErrors(form)) this.handleErrors()
      }
    },

    input() {
      const isDirty = this.hasDirtyInputs()
      this.toggleCommitButtonAbility(!isDirty)
    },

    toggleCommitButtonAbility(value) {
      this.commitButtonTarget.disabled = value
      this.cancelButtonTarget.classList.toggle('hidden', value)
      this.toggleClasses.map((klass) => this.commitButtonTarget.classList.toggle(klass, value))
    },

    hasDirtyInputs() {
      const dirtyInputs = this.formInputs().filter((input) => {
        if(input.type === 'checkbox') return true

        return input.defaultValue !== input.value
      })
      return dirtyInputs.length > 0
    },

    formInputs() {
      return Array.from(
        this.element
          .querySelector('form')
          .querySelectorAll('input:not([type=hidden]):not([type=submit]),textarea:not([disabled]),select:not([disabled])')
      )
    },

    formErrors(form) {
      return form.getElementsByClassName('is-invalid').length > 0
    }
  })

  controller.addAttrsToForm()
}
