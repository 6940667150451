import ApplicationController from './application_controller'
import debounced from 'debounced'
import currency from 'currency.js'

export default class extends ApplicationController {
  static targets = ['balance', 'offlineRefundAmount', 'paymentRefundAmount', 'button']

  initialize() {
    super.initialize()
    debounced.initialize()
  }

  connect() {
    super.connect()
    this.updateBalance()
  }

  updateBalance() {
    var newBalance = this.computeBalance()
    this.balanceTarget.classList.toggle('text-red-500', newBalance < 0)
    if (newBalance < 0) {
      newBalance = currency(0)
    }
    this.balanceTarget.innerHTML = newBalance.format()
    this.buttonTarget.innerHTML = `Refund ${this.refundTotal().format()}`
  }

  refundTotal() {
    return this.offlineRefundAmount().add(this.paymentRefundTotal())
  }

  computeBalance() {
    return this.initialBalance()
      .subtract(this.offlineRefundAmount())
      .subtract(this.paymentRefundTotal())
  }

  initialBalance() {
    return currency(this.balanceTarget.dataset.balance)
  }

  offlineRefundAmount() {
    return currency(this.offlineRefundAmountTarget.value)
  }

  paymentRefundTotal() {
    return this.paymentRefundAmountTargets.reduce(
      (total, paymentRefundTarget) => total.add(currency(paymentRefundTarget.value)),
      currency(0)
    )
  }
}
