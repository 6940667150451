import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form']

  change(event) {
    if (event.target.defaultValue === event.target.value) { return }

    this.formTarget.requestSubmit()
  }
}
