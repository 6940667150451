import ApplicationController from './application_controller'
import currency from 'currency.js'

export default class extends ApplicationController {
  static values = { ordersTotal: Number, existingCreditItemsTotal: Number }
  static targets = [
    'submitButton',
    'quantityInput',
    'selectProductInput',
    'kegCreditTotal',
    'orderTotal',
    'callToAction'
  ]

  connect() {
    this.updateTargets()
  }

  productChanged(_e) {
    this.updateTargets()
  }

  quantityChanged(_e) {
    this.updateTargets()
  }

  updateTargets() {
    this.orderTotalTarget.innerHTML = this.formatCurrency(this.orderTotal())
    this.kegCreditTotalTarget.innerHTML = this.formatCurrency(this.totalKegCredit())
    this.toggleSubmitButton()
  }

  orderTotal() {
    return this.ordersTotalValue - this.totalKegCredit()
  }

  totalKegCredit() {
    const quantity = parseInt(this.quantityInputTarget.value) || 0
    if (quantity > 0) { this.quantityInputTarget.value = quantity  }
    const kegUnitPrice = this.selectProductInputTarget.options[this.selectProductInputTarget.selectedIndex].dataset.price || 0
    return this.existingCreditItemsTotalValue + (quantity * kegUnitPrice)
  }

  formatCurrency(amount) {
    return currency(amount / 100.00).format();
  }

  toggleSubmitButton() {
    if (!this.quantityInputTarget.value || !this.selectProductInputTarget.selectedIndex) {
      this.removeErrorCssAndHideCTA()
      this.disableSubmitButton()
    } else if (this.quantityInputTarget.value > 0 && this.orderTotal() >= 0) {
      this.removeErrorCssAndHideCTA()
      this.enableSubmitButton()
    } else {
      this.addErrorCssAndShowCTA()
      this.disableSubmitButton()
    }
  }

  removeErrorCssAndHideCTA() {
    this.orderTotalTarget.classList.remove('text-red-500')
    this.callToActionTarget.classList.add('hidden')
  }

  addErrorCssAndShowCTA() {
    this.orderTotalTarget.classList.add('text-red-500')
    this.callToActionTarget.classList.remove('hidden')
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  submitForm() {
    this.submitButtonTarget.innerText = 'Applying...'
    this.submitButtonTarget.disabled = true
    document.getElementById('new_credit_item_form').requestSubmit()
  }
}
