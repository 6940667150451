import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['search', 'checkbox', 'name']

  connect() {
    this.toggleOrganizationInputs()
  }

  toggleOrganizationInputs(e) {
    if (e) e.preventDefault()

    if (this.checkboxTarget.checked) {
      this.searchTarget.classList.add('hidden')
      this.nameTarget.classList.remove('hidden')
    } else {
      this.searchTarget.classList.remove('hidden')
      this.nameTarget.classList.add('hidden')
    }
  }
}
