import ApplicationController from './application_controller'

export default class extends ApplicationController {
  discard(event) {
    if (!confirm('Are you sure you want to remove this customer?')) {
      return
    }
    this.stimulate('GroupMembershipReflex#discard', event.currentTarget, { selectors: ['.customer-list-morph-target'] })
  }
}
