import ApplicationController from './application_controller'
import Manipulator from 'lib/dom/manipulator'
import { useDrawer } from './mixins'

export default class extends ApplicationController {
  static targets = ['drawer', 'drawerTrigger', 'drawerBackdrop', 'drawerOverlay']

  connect() {
    useDrawer(this)
    this.position = Manipulator.getDataAttribute(this.element, 'position') || 'left'
  }

  disconnect() {
    this.close()
  }
}
