import ApplicationController from './application_controller'
import WebAnimation from 'lib/animation'
import Focusable from 'lib/dom/focusable'
import { useLockScroll } from './mixins'

export default class extends ApplicationController {
  static targets = ['modal', 'trigger', 'backdrop', 'overlay']

  connect() {
    super.connect()

    useLockScroll(this)

    if (this.hasModalTarget) this.close()

    if (!this.isPreview && this.hasOverlayTarget && this.overlayTarget.dataset.open === 'true') {
      this.open()
    }
  }

  disconnect() {
    super.disconnect()
    if (this.hasOverlayTarget) this.close()
  }

  open(e) {
    e?.preventDefault()

    this.lockScroll()
    this.hideTriggerMenu(e)
    if (this.overlayTarget.classList.contains('hidden')) {
      WebAnimation.enter(this.modalTarget, {
        keyframes: {
          opacity: [0, 1],
          transform: ['scale(0.95) translateY(4px)', 'scale(1) translateY(0)']
        },
        options: {
          duration: 200,
          easing: 'ease-out',
          fill: 'forwards'
        },
        elementToShow: this.overlayTarget,
        onfinish: () => {
          // Focus on the first el
          document.addEventListener('focus', this.trapFocusOnModal, true)
          Focusable.firstFocusableEl(this.modalTarget).focus()

          this.dispatchEvent('modal-open')
        }
      })
    } else {
      // Focus on the first el
      document.addEventListener('focus', this.trapFocusOnModal, true)
      Focusable.firstFocusableEl(this.modalTarget).focus()

      this.dispatchEvent('modal-open')
    }
  }

  openRemote(e) {
    e?.preventDefault()

    const targetHandle = e.target.closest('[data-target-modal]').dataset.targetModal
    const modalInstance = this.element.querySelector(`[data-modal-key="${targetHandle}"]`)[
      'modal-component'
    ]
    modalInstance.open(e)
  }

  close(e) {
    e?.preventDefault()

    this.unlockScroll()
    if (!this.overlayTarget.classList.contains('hidden')) {
      WebAnimation.leave(this.modalTarget, {
        keyframes: {
          opacity: [1, 0],
          transform: ['scale(1) translateY(0)', 'scale(0.95) translateY(4px)']
        },
        options: {
          duration: 150,
          easing: 'ease-in',
          fill: 'forwards'
        },
        elementToHide: this.overlayTarget,
        onfinish: () => {
          this.dispatchEvent('modal-closed')
          this.clearForm()
          // Remove trap behaviour
          document.removeEventListener('focus', this.trapFocusOnModal, true)
        }
      })
    } else {
      this.dispatchEvent('modal-closed')
      this.clearForm()
      // Remove trap behaviour
      document.removeEventListener('focus', this.trapFocusOnModal, true)
    }

    // if this modal is loaded within a turbo frame, we'll need to reset the turbo frame, otherwise you can't open the modal again
    if (this.element.dataset.modalTurboFrame) {
      document.getElementById(this.element.dataset.modalTurboFrame)?.removeAttribute('src')
    }
  }

  closeWithKeyboard(e) {
    if (
      (e.key === 'Esc' || e.key === 'Escape') &&
      !this.overlayTarget.classList.contains('hidden')
    ) {
      this.close(e)
    }
  }

  hideTriggerMenu(e) {
    if (!e) return

    const menu = e.target.closest('[data-target="dropdown-component.menu"]')
    if (menu) menu.classList.add('hidden')
  }

  submitForm() {
    const form = this.element.querySelector('form')
    form.querySelector('input[type=submit]').click()
  }

  clearForm() {
    const formElement = this.modalTarget.querySelector('form')
    if (!formElement) return

    formElement.reset()
    formElement
      .querySelectorAll('div[data-invalid="true"], span.validation-error')
      .forEach((el) => {
        switch (el.tagName) {
          case 'DIV':
            el.setAttribute('data-invalid', false)
            break
          case 'SPAN':
            el.classList.add('hidden')
            break
          default:
            break
        }
      })
  }

  dispatchEvent = (eventName, options = {}) => {
    this.modalTarget.dispatchEvent(
      new CustomEvent(eventName, { bubbles: false, cancelable: true, ...options })
    )
  }

  trapFocusOnModal = (event) => {
    Focusable.trapFocus(event, { element: this.modalTarget })
  }
}
