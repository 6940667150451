import ApplicationController from './application_controller'
import Combobox from '@github/combobox-nav'

export default class extends ApplicationController {
  static targets = ['input', 'list', 'item']

  start() {
    this.combobox?.destroy()
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
  }

  listTargetConnected() {
    this.start()
    if (this.hasItemTarget) {
      this.itemTargets.forEach(item => {
        item.addEventListener('combobox-commit', () => {
          item.querySelector('a').click()
        })
      })
    }
  }

  toggleInput(e) {
    if(e.target.value) {
      this.inputTarget.classList.remove('disabled')
      this.inputTarget.disabled = false

    } else {
      this.inputTarget.classList.add('disabled')
      this.inputTarget.disabled = true
    }
  }

  stop() {
    this.combobox?.stop()
  }

  disconnect() {
    this.combobox?.destroy()
  }
}
