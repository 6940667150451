import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['idInput', 'destroyInput']

  remove(event) {
    event.preventDefault()

    if (this.idInputTarget.value) {
      this.destroyInputTarget.value = true
      this.element.classList.add('hidden')
    } else {
      this.element.remove()
    }
  }
}
