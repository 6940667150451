import ApplicationController from './application_controller'
import { debounce } from 'lodash'

export default class extends ApplicationController {
  static targets = ['form', 'hint']

  connect() {
    this.__setInitialFormData()
    this.submitForm = debounce(this.submitForm, 500)

    if (this.hasHintTarget) {
      this.hintText = this.hintTarget?.innerText
      document.addEventListener('turbo:submit-start', this.showLoading)
      document.addEventListener('turbo:submit-end', this.removeLoading)
    }
  }

  disconnect() {
    if (this.hasHintTarget) {
      document.removeEventListener('turbo:submit-start', this.showLoading)
      document.removeEventListener('turbo:submit-end', this.removeLoading)
    }
  }

  submitForm(event) {
    if (!this.hasFormTarget) throw "Couldn't find form element to submit"

    if (this.formHasChanges()) this.formTarget.requestSubmit()
  }

  showLoading = (e) => {
    if (this.hasHintTarget && e.target.id === this.formTarget.id) {
      const loadingLabel = this.hintTarget.dataset.loadingLabel || 'Saving...'
      this.hintTarget.innerHTML = `<i class="far fa-circle-notch animate-spin mr-1"></i> ${loadingLabel}`
    }
  }

  removeLoading = (e) => {
    if (this.hasHintTarget && e.target.id === this.formTarget.id) {
      this.hintTarget.innerHTML = this.hintText
      this.__setInitialFormData()
    }
  }

  formHasChanges() {
    return (
      this.__representFormData(this.initialFormData) !==
      this.__representFormData(new FormData(this.formTarget))
    )
  }

  __setInitialFormData() {
    this.initialFormData = new FormData(this.formTarget)
  }

  __representFormData(formData) {
    return JSON.stringify([
      ...Array.from(formData.entries()).filter((pair) => pair[0] !== 'authenticity_token' && pair[0] !== 'search_terms')
    ])
  }
}
