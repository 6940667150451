import Dropzone from 'dropzone'
import ApplicationController from './application_controller'
import { useDropzone } from './mixins/useDropzone'

export default class extends ApplicationController {
  static targets = ['input']

  connect() {
    useDropzone(this)
    this.dropZone = this.createDropZone(this)
    this._hideFileInput()
    this._bindEvents()
    Dropzone.autoDiscover = false
  }
}
