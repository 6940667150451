import DOMEvents from 'lib/dom/events'

export const useQuantityInput = (controller) => {
  return Object.assign(controller, {
    onQuantityInputChange(event) {
      // eslint-disable-next-line
      if (!event.target.value || event.target.value == 0) {
        const row = this._getClosestRow(event.target)
        this._removeSelectedRow(row)
        row.removeAttribute('data-selected')
      } else {
        const row = this._getClosestRow(event.target)
        this._addSelectedRow(row)
        row.setAttribute('data-selected', true)
      }
    },

    onQuantityInputKeyPress(event) {
      switch (event.keyCode) {
        case DOMEvents.keyCodes.ESC:
          if (this.show) this.hideListbox()
          break
        case DOMEvents.keyCodes.RETURN:
          this.submit()
          break
        case DOMEvents.keyCodes.RIGHT:
          // TODO: Re-enable when possibility to change price
          // if (event.shiftKey) {
          //   event.preventDefault()
          //   const currentRowIndex = this._findCurrentRowIndex(event.target)
          //   this._focusSiblingPriceInput(currentRowIndex)
          // }
          break
        case DOMEvents.keyCodes.TAB: {
          const currentRowIndex = this._findCurrentRowIndex(event.target)

          if (event.shiftKey) {
            event.preventDefault()
            this._focusQuantityInputPreviousRow(currentRowIndex)
          } else if (currentRowIndex < this.itemsCount - 1) {
            event.preventDefault()
            this._focusQuantityInputNextRow(currentRowIndex)
          }
          break
        }
      }
    },

    onFormSubmitted(event) {
      event.preventDefault()
    },

    _focusQuantityInputPreviousRow(currentRowNumber) {
      if (currentRowNumber === 0) this.searchInputTarget.focus()
      else {
        const previousRowTargetIndex = this.filterableTargets.findIndex((t) => t.id === this.visibleFilterableTargets()[currentRowNumber - 1].id)
        this.quantityInputTargets[previousRowTargetIndex].focus()
      }
    },

    _focusSiblingQuantityInput(currentRowNumber) {
      const currentRowTargetIndex = this.filterableTargets.findIndex((t) => t.id === this.visibleFilterableTargets()[currentRowNumber].id)
      this.quantityInputTargets[currentRowTargetIndex].focus()
    },

    _focusQuantityInputNextRow(currentRowNumber) {
      if (currentRowNumber === this.itemsCount - 1) return

      const previousRowTargetIndex = this.filterableTargets.findIndex((t) => t.id === this.visibleFilterableTargets()[currentRowNumber + 1].id)
      this.quantityInputTargets[previousRowTargetIndex].focus()
    }
  })
}
