import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['submitButton', 'brandSelect', 'containerTypeSelect', 'newContainerFooter']

  validateInputs(event) { this.toggleSubmitButton(this.isValid()) }

  validatePresence(value) {
    if (value == null || value === '') {
      return false
    } else {
      return true
    }
  }

  isValid() {
    return (
      this.validatePresence(this.brandValue()) && this.validatePresence(this.containerTypeValue())
    )
  }

  brandValue() {
    return this.brandSelectTarget.querySelector('#product_brand_id').value
  }

  containerTypeValue() {
    return this.containerTypeSelectTarget.querySelector(
      '#product_invoiceable_attributes_products_container_types_id'
    ).value
  }


  toggleSubmitButton(boolean) {
    this.submitButtonTarget.disabled = !boolean
    if (boolean) {
      this.submitButtonTarget.classList.remove('disabled')
    } else {
      this.submitButtonTarget.classList.add('disabled')
    }
  }
}
