import ApplicationController from './application_controller'
import DOMEvents from 'lib/dom/events'

export default class extends ApplicationController {
  static targets = ['preview', 'form', 'input']
  static values = { trigger: String }

  /**
   * Possible state (preview / edit / submitting)
   */
  get state() {
    return this.element.getAttribute('data-state')
  }

  set state(value) {
    this.element.setAttribute('data-state', value)
  }

  /**
   * Possible state (idle / changed). This will prevent to do unnecessary submit
   */
  get formState() {
    return this.element.getAttribute('data-form-state')
  }

  set formState(value) {
    this.element.setAttribute('data-form-state', value)
  }

  get selectController() {
    return this.element.querySelector('div[data-controller="select-component"]')?.[
      'select-component'
    ]
  }

  get flatpickerCalendar() {
    return document.querySelector('div.flatpickr-calendar')
  }

  connect() {
    window.addEventListener('click', this.handleClickOutside)
    this.previewTarget.addEventListener('keydown', this.handlePreviewKeyDown)
    this.inputTarget.addEventListener('change', this.handleInputChange)
    this.inputTarget.addEventListener('keydown', this.handleInputKeyDown)

    if (this.hasTriggerValue && this.triggerValue === 'change')
      this.inputTarget.addEventListener('change', this.submit)
  }

  disconnect() {
    window.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state === 'preview' || this.state === 'submitting') return

    const controller = event.target.closest(
      `[data-controller="${this.element.dataset.controller}"]`
    )
    if (this.element === controller) {
      return
    }

    this.submit()
  }

  disconnect() {
    window.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state === 'preview' || this.state === 'submitting') return

    const isIncontroller = this.element.contains(event.target)
    // Flatpicker calendar is not a child of the faltpickr controller.
    const isFlatpickerCalendar = this.flatpickerCalendar?.contains(event.target) || false

    if (isIncontroller || isFlatpickerCalendar) {
      return
    }

    this.submit()
  }

  handleInputKeyDown = (event) => {
    switch (event.keyCode) {
      case DOMEvents.keyCodes.TAB:
      case DOMEvents.keyCodes.RETURN:
        this.submit()
        break
      default:
        this.formState = 'changed'
    }
  }

  handlePreviewKeyDown = (event) => {
    switch (event.keyCode) {
      case DOMEvents.keyCodes.RETURN:
        event.preventDefault()
        this.editMode()
        break
      case DOMEvents.keyCodes.SPACE:
        this.editMode()
        break
    }
  }

  handleInputChange = (event) => {
    this.formState = 'changed'
  }

  submit = () => {
    if (this.formState === 'changed') {
      this.formTarget.requestSubmit()
      this.state = 'submitting'
      this.formState = 'idle'
    } else this.previewMode()
  }

  previewMode = () => {
    this.state = 'preview'
    this.previewTarget.classList.remove('hidden')
    this.formTarget.classList.add('hidden')
  }

  editMode = () => {
    this.state = 'edit'
    this.previewTarget.classList.add('hidden')
    this.formTarget.classList.remove('hidden')

    if (!!this.selectController) {
      this.selectController.choices.showDropdown()
    } else this.inputTarget.focus()
  }
}
