import { useFilterable } from './mixins'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['filterable']

  connect() {
    useFilterable(this)

    this.initFilterable()

    if (!!this.lazyContent()) {
      // if the list is lazy loaded, wait for it then re-set the list of searchable items to fuse
      this.observer = new MutationObserver(this.contentLoaded)
      this.observer.observe(this.lazyContent(), {
        childList: true,
        attribute: false,
        subtree: false
      })
    }
  }

  disconnect() {
    this.resetList()
  }

  onSearchInputChange(event) {
    this.search(event.target.value)
  }

  contentLoaded = (mutationsList, observer) => {
    if (mutationsList.some((mutation) => !!mutation.addedNodes.length)) {
      this.fuse.setCollection(this.getSearchableList())
    }
  }

  lazyContent() {
    return this.element.querySelector('turbo-frame[loading="lazy"]')
  }
}
