import ApplicationController from './application_controller'
import Manipulator from 'lib/dom/manipulator'
import Session from 'lib/dom/session'
import { useOverflowNavigation } from './mixins'

const MOBILE_AND_TABLET_BREAKPOINT = 1024

export default class extends ApplicationController {
  static targets = ['tab', 'panel', 'tabsContainer']

  connect() {
    useOverflowNavigation(this)
    super.connect()
    this.activeTabClasses = (this.data.get('activeTab') || 'text-primary').split(' ')
    this.inactiveTabClasses = (this.data.get('inactiveTab') || 'text-default').split(' ')
    this.activeTabCountClasses = this.data.get('countActiveTab')?.split(' ')
    this.inactiveTabCountClasses = this.data.get('countInactiveTab')?.split(' ')
    this.tabsId = Manipulator.getDataAttribute(this.element, 'id') || ''
    this.device = null

    // This changes the activeId to the scope from the URL if it exists on the customer
    // relationship status tabs
    const scope = new URL(window.location).searchParams.get('scope')
    if (this.tabsId === 'customer-relationships-status' && scope) {
      this.activeId = `tab-${scope}`
    }

    window.addEventListener('load', () => {
      this.showTab()
      this.onWindowResize()
    })

    this._initializeTabs()

    // Will position the cursor and initialize the active tab
    window.addEventListener('turbo:load', this._initializeTabs)
    window.addEventListener('load', this._initializeTabs)

    window?.addEventListener('resize', this.onWindowResize)
  }

  disconnect() {
    window?.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('turbo:load', this._initializeTabs)
    window.removeEventListener('load', this._initializeTabs)
  }

  onScrollLeftBtnClick() {
    this._scrollLeft(this.tabsContainer)
  }

  onScrollRightBtnClick() {
    this._scrollRight(this.tabsContainer)
  }

  change(event) {
    event?.preventDefault()

    // If target specifies an index, use that
    if (event.currentTarget.dataset.activeId) {
      this.activeId = event.currentTarget.dataset.activeId
      // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id) {
      this.activeId = this.visibleTabTargets.findIndex(
        (tab) => tab.id === event.currentTarget.dataset.id
      )
      // Otherwise, use the index of the current target
    } else {
      this.activeId = event.currentTarget.id
    }

    window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }

  showTab() {
    this.visibleTabTargets.forEach((tab, index) => {
      const panel = this.visiblePanelTargets[index]

      if (tab.id === this.activeId) {
        this._setTabToActive(tab, panel)
      } else if (!panel.classList.contains('hidden')) {
        this._setTabToInactive(tab, panel)
      }
    })
  }

  onWindowResize() {
    if (window.innerWidth <= MOBILE_AND_TABLET_BREAKPOINT) {
      if (!this.device || this.device === 'desktop') {
        this._showMobileTabs()
        this.showTab()
      }
      this.device = 'mobile'
    } else {
      if (!this.device || this.device === 'mobile') {
        this._hideMobileTabs()
        // Check if the active tab was a mobile one, if true set the active tabs to the first one
        if (!this.visibleTabTargets.some((tab) => tab.id === this.activeId))
          this.activeId = this.visibleTabTargets[0].id
        this.showTab()
      }
      this.device = 'desktop'
    }
  }

  _showMobileTabs() {
    this.tabTargets.forEach((tab, index) => {
      if (tab.getAttribute('data-mobile')) {
        tab.classList.remove('hidden')
        tab.setAttribute('data-visible', 'true')
        this.panelTargets[index].setAttribute('data-visible', 'true')
      }
    })
  }

  _hideMobileTabs() {
    this.tabTargets.forEach((tab, index) => {
      if (tab.getAttribute('data-mobile')) {
        tab.classList.add('hidden')
        tab.setAttribute('data-visible', 'false')
        if (this.panelTargets[index]) {
          this.panelTargets[index].setAttribute('data-visible', 'false')
          this._setTabToInactive(tab, this.panelTargets[index])
        }
      }
    })
  }

  _getTabCountElement(tab) {
    return tab.getElementsByClassName('tabs_component__count')?.[0]
  }

  _getActiveTabIndicator() {
    return this.element.getElementsByClassName('tabs_component__indicator')?.[0]
  }

  _getWidthTab(tab) {
    return tab.offsetWidth
  }

  _positionTabIndicator(tab) {
    if (!tab || !this._getActiveTabIndicator()) return

    this._getActiveTabIndicator().setAttribute(
      'style',
      `width:${this._getWidthTab(tab)}px; transform: translateX(${this._calculateTranslateX()}px);`
    )
  }

  _calculateTranslateX() {
    return this.visibleTabTargets
      .splice(0, this.index)
      .reduce((acc, curr) => (acc += this._getWidthTab(curr)), 0)
  }

  _setTabToActive(tab, panel) {
    panel.classList.remove('hidden')
    this._positionTabIndicator(tab)
    this._scrollToTab(tab)
    this._applyActiveClasses(tab)
    tab.removeAttribute('tabindex')
    Manipulator.setAriaAttribute(tab, 'selected', true)
  }

  _setTabToInactive(tab, panel) {
    panel.classList.add('hidden')
    tab.tabIndex = -1
    this._applyInactiveClasses(tab)
    Manipulator.removeAriaAttribute(tab, 'selected')
  }

  _applyActiveClasses(tab) {
    tab.classList.remove(...this.inactiveTabClasses)
    tab.classList.add(...this.activeTabClasses)
    this._getTabCountElement(tab)?.classList.remove(...this.inactiveTabCountClasses)
    this._getTabCountElement(tab)?.classList.add(...this.activeTabCountClasses)
  }

  _applyInactiveClasses(tab) {
    tab.classList.remove(...this.activeTabClasses)
    tab.classList.add(...this.inactiveTabClasses)
    this._getTabCountElement(tab)?.classList.add(...this.inactiveTabCountClasses)
    this._getTabCountElement(tab)?.classList.remove(...this.activeTabCountClasses)
  }

  _initializeTabs = () => {
    if (!this.isPreview) {
      this.showTab()
      this.onWindowResize()
    }
  }

  get visibleTabTargets() {
    return this.tabTargets.filter((tab) => !!Manipulator.getDataAttribute(tab, 'visible'))
  }

  get visiblePanelTargets() {
    return this.panelTargets.filter((panel) => !!Manipulator.getDataAttribute(panel, 'visible'))
  }

  get sessionKey() {
    return `tabs-${this.tabsId}-${window.location.pathname}-currentTab`
  }

  get activeId() {
    return Session.getSessionStorage(this.sessionKey) || this.visibleTabTargets[0].id
  }

  set activeId(value) {
    Session.setSessionStorage(this.sessionKey, value)
    this.showTab()
  }

  get index() {
    return this.visibleTabTargets.findIndex((tab) => tab.id === this.activeId)
  }
}
