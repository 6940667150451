import ApplicationController from './application_controller'
import { useSpinner } from './mixins/useSpinner'

export default class extends ApplicationController {
  static targets = ['spin', 'container']

  connect() {
    super.connect()
    useSpinner(this)
  }

  disconnect() {
    this.tearDownSpinner()
  }
}
