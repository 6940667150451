import ApplicationController from './application_controller'

// Connects to data-controller="anchors"
export default class extends ApplicationController {
  static targets = ["anchor"];

  connect() {
    let currentAnchor = this.stripAnchor(document.location)
    if (currentAnchor) {
      document.location.href = `#${currentAnchor}`;
    }

    this.highlightAnchor(document.location);
  }

  jumpAndHighlightAnchor(event) {
    this.highlightAnchor(event.currentTarget.href)
  }

  highlightAnchor(anchorUrl) {
    let currentAnchor = this.stripAnchor(anchorUrl)

    if (currentAnchor) {
      this.anchorTargets.forEach(function(anchorTarget) {
        if (anchorTarget.href.includes(currentAnchor)) {
          anchorTarget.classList.remove(...anchorTarget.dataset.unhighlightedClasses.split(" "))
          anchorTarget.classList.add(...anchorTarget.dataset.highlightedClasses.split(" "))
        } else {
          anchorTarget.classList.remove(...anchorTarget.dataset.highlightedClasses.split(" "))
          anchorTarget.classList.add(...anchorTarget.dataset.unhighlightedClasses.split(" "))
        }
      })
    }
  }

  stripAnchor(anchorURL) {
    return anchorURL.toString().split("#")[1];
  }
}
