import Manipulator from 'lib//dom/manipulator'

export const useExpandable = (controller) => {
  return Object.assign(controller, {
    onExpandableTriggerClicked(event) {
      const expandablePanelId = event.currentTarget.getAttribute('aria-controls')

      if (Manipulator.getDataAttribute(event.currentTarget, 'expanded')) {
        this._collapse(event.currentTarget, expandablePanelId)
      } else {
        this._expand(event.currentTarget, expandablePanelId)
      }
    },

    _expand(expandableTrigger, id) {
      if (Manipulator.getDataAttribute(expandableTrigger, 'expanded')) return

      const expandablePanel = this._getExpandablePanel(id)
      expandablePanel?.classList.remove('hidden')
      expandableTrigger.setAttribute('aria-expanded', true)
      expandableTrigger?.setAttribute('data-expanded', true)
      expandablePanel?.setAttribute('data-hidden', false)
    },

    _collapse(expandableTrigger, id) {
      if (!Manipulator.getDataAttribute(expandableTrigger, 'expanded')) return
      const expandablePanel = this._getExpandablePanel(id)

      expandablePanel?.classList.add('hidden')
      expandableTrigger.setAttribute('aria-expanded', false)
      expandableTrigger.setAttribute('data-expanded', false)
      expandablePanel?.setAttribute('data-hidden', true)
    },

    _getExpandablePanel(id) {
      return this.expandablePanelTargets.find((target) => target.id === id)
    },

    _expandAll() {
      this.expandableTriggerTargets.forEach((target) => this._expand(target, target.id))
    },

    _collapseAll() {
      this.expandableTriggerTargets.forEach((target) => this._collapse(target, target.id))
    }
  })
}
