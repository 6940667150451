import ApplicationController from './application_controller'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = ['warehouseSelectForm', 'originWarehouse', 'destinationWarehouse', 'form', 'container', 'spin']

  connect() {
    super.connect()
    useSpinner(this)
  }

  disconnect() {
    super.disconnect()
  }

  beforeUpdate() {
    this.showSpinner()
  }

  afterUpdate() {
    this.hideSpinner()
  }

  submit() {
    if (!this.destinationWarehouseTarget.value) {
      return
    }
    this.warehouseSelectFormTarget.submit()
  }

  cancel() {
    if(!confirm('Are you sure you want to cancel this transfer?')) {
      return
    }

    this.stimulate('Transfer#cancel')
  }

  onCommentInputBlur() {
    this.stimulate('Transfer#update', this.formTarget)
  }
}
