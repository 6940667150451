import ApplicationController from './application_controller'
import * as iziToast from 'izitoast'
import { isEmpty } from 'lodash'

export default class extends ApplicationController {
  static targets = ['template']
  static values = {
    id: String,
    type: String,
    title: String,
    description: String,
    persist: Boolean,
    link: Object
  }

  get iconOptions() {
    switch (this.typeValue) {
      case 'success':
        return { icon: 'far fa-check', iconColor: '#3fa936' }
      case 'error':
        return { icon: 'far fa-times-circle', iconColor: '#de4b48' }
      case 'warning':
        return { icon: 'far fa-exclamation-triangle', iconColor: '#c88804' }
      case 'info':
        return { icon: 'far fa-exclamation-circle', iconColor: '#489ccf' }
    }
  }

  get alertOptions() {
    return {
      id: `alert-notification__${this.idValue}`,
      layout: 2,
      closeOnClick: true,
      position: 'bottomLeft',
      maxWidth: 400,
      ...this.iconOptions
    }
  }

  connect() {
    super.connect()
    this.removeAlertContainerFrame()
  }

  idValueChanged() {
    this.alertHandler()
  }

  alertHandler = (_event) => {
    const options = Object.assign(this.alertOptions, {
      title: this.typeValue,
      message: this.descriptionValue,
      timeout: this.persistValue ? 0 : 4000,
      progressBar: !this.persistValue,
      buttons: !isEmpty(this.linkValue) && [
        [
          `<button>${this.linkValue.placeholder}</button>`,
          (_instance, _toast) => window.open(this.linkValue.href, '_blank')
        ]
      ]
    })
    if (iziToast[this.typeValue]) {
      iziToast[this.typeValue](options)
    }
  }

  removeAlertContainerFrame = () => {
    this.element.remove()
  }
}
