import ApplicationController from './application_controller'
import debounced from 'debounced'
import { useSearch, useSpinner, useForm } from './mixins'

// TODO make Spin a mixin, instead of inheriting
export default class extends ApplicationController {
  static targets = ['input', 'search', 'spin', 'container']

  initialize() {
    super.initialize()
    debounced.initialize({ input: { wait: 300 } })
  }

  connect() {
    useSearch(this)
    useSpinner(this)
    useForm(this)
    super.connect()

    this.selectors = this.element.getAttribute('data-selectors')?.split(' ')
  }

  beforeReflex() {
    this.showSpinner()
  }

  afterReflex() {
    this.hideSpinner()
  }

  applyTag(event) {
    event.preventDefault()
    this.stimulate('abstract#apply_tag', event.target)
  }

  clearTag(event) {
    this.stimulate('abstract#clear_tag', event.target)
  }
}
