import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['showcase', 'leftButton', 'rightButton', 'variant']

  connect() {
    super.connect()
    this.displayRightButton()
  }

  scrollToRight() {
    const { scrollLeft, offsetWidth } = this.showcaseTarget
    this.showcaseTarget.scrollTo({ left: scrollLeft + offsetWidth, behavior: 'smooth' })
  }

  scrollToLeft() {
    const { scrollLeft, offsetWidth } = this.showcaseTarget
    this.showcaseTarget.scrollTo({ left: scrollLeft - offsetWidth, behavior: 'smooth' })
  }

  toggleButtons() {
    this.displayLeftButton()
    this.displayRightButton()
  }

  displayLeftButton() {
    const { scrollLeft } = this.showcaseTarget
    if (scrollLeft === 0) {
      this.leftButtonTarget.classList.add('hidden')
      this.rightButtonTarget.classList.remove('hidden')
    } else {
      this.leftButtonTarget.classList.remove('hidden')
    }
  }

  displayRightButton() {
    const { scrollLeft, scrollWidth, clientWidth } = this.showcaseTarget
    const scrollPositionMaxRight = scrollLeft === scrollWidth - clientWidth
    if (scrollPositionMaxRight) {
      this.rightButtonTarget.classList.add('hidden')
    } else if (scrollLeft > 0) {
      this.rightButtonTarget.classList.remove('hidden')
    }
  }
}
