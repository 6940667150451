import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['customerContainer', 'orderContainer', 'submitButton', 'orderSelect', 'customerSelect']

  connect() {
    this.submitButtonTarget.disabled = true
  }

  setReturnType(event) {
    this.orderContainerTarget.classList.toggle('hidden', event.target.value === 'blank')
    this.customerContainerTarget.classList.toggle('hidden', event.target.value === 'reference')

    let customerValue = this.customerSelectTarget.querySelector('select').value
    let orderValue = this.orderSelectTarget.querySelector('select').value

    if(event.target.value === 'blank') {
      this._toggleSubmitButton(customerValue)
    }

    if(event.target.value === 'reference') {
      this._toggleSubmitButton(orderValue)
    }
  }

  toggleSubmitButton(event) {
    this._toggleSubmitButton(event.target.value)
  }

  _toggleSubmitButton(value) {
    if(value == null || value === '') {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }
  }
}
