import ApplicationController from './application_controller'
import WebAnimation from 'lib/animation'
import DOM from 'lib/dom/dom'
import Session from 'lib/dom/session'

export default class extends ApplicationController {
  static targets = ['sidebar', 'sidebarCollapsed', 'closeBtn', 'openBtn',  'mobileCloseBtn', 'mobileOpenBtn', 'mainContent']

  initialize() {
    if (DOM.isMobile()) {
      this._hideSidebar()
    }
  }

  connect() {
    if (!DOM.isMobile()) {
      if (localStorage.menuState === 'collapsed') {
        document.querySelector('#full-nav').classList.add('hidden');
        document.querySelector('#collapsed-nav').classList.remove('hidden');
        this.mainContentTarget.classList.remove('layout__content')
        this.mainContentTarget.classList.add('layout__content_small')
      } else {
        document.querySelector('#full-nav').classList.remove('hidden');
        document.querySelector('#collapsed-nav').classList.add('hidden');
        this.mainContentTarget.classList.add('layout__content')
        this.mainContentTarget.classList.remove('layout__content_small')
      }
    }
  }

  get isVisible() {
    return this.sidebarTarget.getAttribute('data-visible') === 'true'
  }

  set visible(value) {
    return this.sidebarTarget.getAttribute('data-visible') === value
  }

  get enterAnimationOptions() {
    return { duration: 150, fill: 'forwards', easing: 'ease-out' }
  }

  get leaveAnimationOptions() {
    return { duration: 100, fill: 'forwards', easing: 'ease-in' }
  }

  get stateInSession() {
    return Session.getSessionStorage(this.sessionKey)
  }

  get sessionKey() {
    return 'ollie-app-sidebar-menu'
  }

  open() {
    if (DOM.isMobile()) {
      Session.setSessionStorage(this.sessionKey, 'OPEN')
      this.mobileOpenBtnTarget.classList.add('hidden')
      this.mobileCloseBtnTarget.classList.remove('hidden')
      this.sidebarTarget.classList.remove('hidden')
      this.element.setAttribute('data-sidebar-visible', true)
      document.querySelector("#mobile-collapsed-open").classList.add("hidden")
      this.mainContentTarget.classList.add('pointer-events-none')
      WebAnimation.enter(this.sidebarTarget, {
        keyframes: {
          transform: ['translateX(-100%)', 'translateX(0)']
        },
        options: this.enterAnimationOptions,
        onfinish: () => {
          this.closeBtnTarget.focus()
        }
      })
      this.mainContentTarget.animate([{ left: '0px', left: '256px' }], this.enterAnimationOptions)
    } else {
      Session.setSessionStorage(this.sessionKey, 'OPEN')
      this.openBtnTarget.classList.add('hidden')
      this.closeBtnTarget.classList.remove('hidden')
      this.mainContentTarget.classList.add('layout__content')
      this.mainContentTarget.classList.remove('layout__content_small')
      this.sidebarTarget.classList.remove('hidden')
      localStorage.menuState = 'full';
    }
  }

  close() {
    if (DOM.isMobile()) {
      Session.setSessionStorage(this.sessionKey, 'CLOSE')
      this.mobileCloseBtnTarget.classList.add('hidden')
      this.mobileOpenBtnTarget.classList.remove('hidden')
      this.sidebarTarget.classList.add('hidden')
      document.querySelector("#mobile-collapsed-open").classList.remove("hidden")
      this.mainContentTarget.classList.remove('pointer-events-none')
      this.element.setAttribute('data-sidebar-visible', false)
      WebAnimation.leave(this.sidebarTarget, {
        keyframes: {
          transform: ['translateX(0)', 'translateX(-100%)']
        },
        options: this.leaveAnimationOptions,
        onfinish: () => {
          this.sidebarTarget.classList.add('hidden')
          this.openBtnTarget.focus()
        }
      })
      this.mainContentTarget.animate([{ left: '260px', left: '0px' }], this.leaveAnimationOptions)
    } else {
      Session.setSessionStorage(this.sessionKey, 'CLOSE')
      document.querySelector('#collapsed-nav').classList.remove('hidden');
      this.closeBtnTarget.classList.add('hidden')
      this.openBtnTarget.classList.remove('hidden')
      this.mainContentTarget.classList.remove('layout__content')
      this.mainContentTarget.classList.add('layout__content_small')
      this.sidebarTarget.classList.add('hidden')
      localStorage.menuState = 'collapsed';
    }
  }

  _hideSidebar() {
    this.closeBtnTarget.classList.add('hidden')
    this.sidebarTarget.classList.add('hidden')
    this.sidebarCollapsedTarget.classList.add('hidden')

    const hiddenElements = ["#order_logo", "#org-dropdown", "#desktop-menu", "#store", "#caret"]
    hiddenElements.forEach((element) => {
      let el = document.querySelector(element)
      if (el) {
        el.classList.add("hidden")
      }
    })

    this.element.setAttribute('data-sidebar-visible', false)
    Session.setSessionStorage(this.sessionKey, 'CLOSE')
  }

  _showSidebar() {
    this.openBtnTarget.classList.add('hidden')
    this.sidebarCollaspedTarget.classList.remove('hidden')
    this.sidebarTarget.classList.add('hidden')
    this.element.setAttribute('data-sidebar-visible', true)
    Session.setSessionStorage(this.sessionKey, 'OPEN')
  }
}
