export const useDrawer = (controller) => {
  return Object.assign(controller, {
    open(e) {
      e?.preventDefault()

      this.lockScroll()

      if (this.drawerOverlayTarget.classList.contains('hidden')) {
        this.drawerOverlayTarget.classList.remove('hidden')
        this.drawerTarget.animate(this.createAnimation().enter, {
          duration: 300,
          easing: 'cubic-bezier(.7,.3,.1,1)',
          fill: 'forwards'
        })
      }
    },

    close(e) {
      if (e) e.preventDefault()

      this.unlockScroll()

      if (!this.drawerOverlayTarget.classList.contains('hidden')) {
        const animation = this.drawerTarget.animate(this.createAnimation().leave, {
          duration: 300,
          easing: 'cubic-bezier(.7,.3,.1,1)',
          fill: 'forwards'
        })
        animation.onfinish = () => {
          this.drawerOverlayTarget.classList.add('hidden')
        }
      }
    },

    closeWithKeyboard(e) {
      if (
        (e.key === 'Esc' || e.key === 'Escape') &&
        !this.drawerOverlayTarget.classList.contains('hidden')
      ) {
        this.close(e)
      }
    },

    lockScroll() {
      this.saveScrollPosition()
      document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')
      document.body.style.top = `-${this.scrollPosition}px`
    },

    unlockScroll() {
      document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')
      this.restoreScrollPosition()
      document.body.style.top = null
    },

    saveScrollPosition() {
      this.scrollPosition = window.pageYOffset || document.body.scrollTop
    },

    restoreScrollPosition() {
      document.documentElement.scrollTop = this.scrollPosition
    },

    createAnimation() {
      let enter
      switch (this.position) {
        case 'left':
          enter = { transform: ['translateX(-100%)', 'translateX(0)'] }
          break
        case 'right':
          enter = { transform: ['translateX(100%)', 'translateX(0)'] }
          break
        case 'bottom':
          enter = {
            transform: ['translateY(100%)', 'translateY(0)']
          }
          break
        default:
          enter = { transform: ['translateX(-100%)', 'translateX(0)'] }
          break
      }

      return {
        enter: enter,
        leave: { transform: [...enter.transform].reverse() }
      }
    }
  })
}
