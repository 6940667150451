class Focusable {
  static allFocusableElements = (container = document) => {
    return container.querySelectorAll(
      'input:not([type="hidden"]), select, textarea, button, [href], [tabindex]:not([tabindex="-1"]'
    )
  }

  static lastFocusableEl = (container = document) => {
    const elements = this.allFocusableElements(container)
    return elements[elements.length - 1]
  }

  static firstFocusableEl = (container = document) => {
    return this.allFocusableElements(container)[0]
  }

  static trapFocus = (event, { element }) => {
    if (!element.contains(event.target)) {
      this.firstFocusableEl(element).focus()
    }
  }
}

export default Focusable
