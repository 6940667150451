import ApplicationController from './application_controller'
import { useExpandable } from 'controllers/mixins/useExpandable'

export default class extends ApplicationController {
  static targets = ['collapsable', 'icon', 'expandableTrigger', 'expandablePanel']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.showIconClass = this.element.getAttribute('data-show-icon-class') || 'fa-angle-down'
    this.hideIconClass = this.element.getAttribute('data-hide-icon-class') || 'fa-angle-left'
    useExpandable(this)
  }

  toggle(event) {
    event.preventDefault()
    this.collapsableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass)
    })
  }

  expand(event) {
    event.preventDefault()

    this.collapsableTargets.forEach((target) => {
      target.classList.remove(this.toggleClass)
    })
  }

  toggleIcon() {
    this.iconTargets.forEach((icon) => {
      icon.classList.toggle(this.showIconClass)
      icon.classList.toggle(this.hideIconClass)
    })
  }
}
