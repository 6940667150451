import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['paymentTermsForm', 'paymentSourceTypeForm']

  submitPaymentSourceType() {
    this.paymentSourceTypeFormTarget.requestSubmit()
  }

  submitPaymentTerms() {
    this.paymentTermsFormTarget.requestSubmit()
  }
}
