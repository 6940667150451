import { colors } from 'stylesheets/theme/colors'

export const useTransitionedRow = (controller) => {
  Object.assign(controller, {
    animateRow(element, options) {
      this.highlightRow(element.closest(options?.rowTag || 'li'))
    },
    highlightRow(row) {
      row.animate([{ backgroundColor: colors.yellow[100] }, { backgroundColor: '#FFF' }], { duration: 1200, easing: 'cubic-bezier(.68,0,.46,.93)' })
    }
  })
}
