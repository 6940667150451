import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['refreshForm', 'brandId']

  typeChanged({ target: { name, value }}) {
    const anchor = this.refreshFormTarget
    anchor.search = new URLSearchParams({ [name]: value, "payment_gateway_form[brand_id]": this.brandIdTarget.value })
    anchor.click()
  }
}
