class WebAnimation {
  static enter(element, { keyframes, options, elementToShow, onfinish, show = true }) {
    if (!element) return

    if (show) elementToShow ? elementToShow.classList.remove('hidden') : element.classList.remove('hidden')
    const animation = element.animate({ ...keyframes }, { ...options })
    animation.onfinish = function () {
      onfinish && onfinish()
    }
   return animation
  }

  static leave(element, { keyframes, options, elementToHide, onfinish, hide = true }) {
    if (!element) return

    const animation = element.animate({ ...keyframes }, { ...options })
    animation.onfinish = function () {
       if (hide) elementToHide ? elementToHide.classList.add('hidden') : element.classList.add('hidden')
       onfinish && onfinish()
    }

    return animation
  }
}

export default WebAnimation
