import ApplicationController from './application_controller'
import Rails from '@rails/ujs'
import Manipulator from 'lib/dom/manipulator'
import { useSpinner, useDrawer } from './mixins'
import debounce from 'lodash/debounce'

export default class extends ApplicationController {
  static targets = ['filterForm', 'search', 'tableSpinner', 'drawerTrigger', 'drawer', 'drawerOverlay', 'drawerBackdrop']

  connect() {
    super.connect()

    this.position = Manipulator.getDataAttribute(this.element, 'position') || 'right'

    useSpinner(this)
    useDrawer(this)

    this.updateQuery = debounce(this.updateQuery, 400)
  }

  beforeReflex(_element, reflex) {
    this._showTableSpinner(reflex)
  }

  finalizeReflex() {
    this._hideTableSpinner()
  }

  updateQuery() {
    if (!this.filterFormTarget) return
    const url = new URL(window.location)
    // Get current scope from URL
    const scope = url.searchParams.get('scope')

    // Get sort params from URL if they exist
    const sortKey = 'q[sort][][field]'
    const sort = url.searchParams.get(sortKey)
    const sortDirectionKey = 'q[sort][][direction]'
    const sortDirection = url.searchParams.get(sortDirectionKey)

    // Reset search params
    url.search = ''
    // Set scope on URL
    url.searchParams.set('scope', scope)

    // Cycle through filters and add to params array
    // inspired from https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/form.coffee
    const inputs = this.filterFormTarget.elements
    const params = []
    Array.from(inputs).forEach((input) => {
      const { name, disabled, value, checked, type } = input

      if (!name || disabled) return
      if (Rails.matches(input, 'fieldset[disabled] *')) return

      if (checked || ['radio', 'checkbox', 'submit'].indexOf(type) === -1) {
        params.push({ name, value })
      }
    })

    if (sort) {
      params.push({ name: sortKey, value: sort })
    }
    if (sortDirection) {
      params.push({ name: sortDirectionKey, value: sortDirection })
    }

    // Add param name and value to URL
    params.map(({ name, value }) => {
      if (!name) return
      url.searchParams.set(name, value)
    })
    // Get search value
    // Invert this into the search component
    const searchTarget = this.searchTarget
    if (searchTarget && searchTarget.value) {
      // Add to url
      url.searchParams.set(searchTarget.name, searchTarget.value)
    } else if (searchTarget) {
      url.searchParams.delete(searchTarget.name)
    }

    history.pushState({}, '', url)
    // undefined, this.element, { selectors: '#search-content, #filter-bar' }
    this.stimulate()
  }

  onFiltersButtonCLick() {
    this.open()
  }

  onCloseFiltersBarClick() {
    this.close()
  }

  _showTableSpinner(reflex_name) {
    // This is the event we receive when we search
    if (!reflex_name.includes('StimulusReflex::Reflex#default_reflex')) {
      return;
    }

    this.tableSpinnerTargets.forEach((target) => {
      this.showSpinner(this._getSpinTarget(target), this._getSpinContainerTarget(target))
    })
  }

  _hideTableSpinner() {
    this.tableSpinnerTargets.forEach((target) => {
      this.hideSpinner(this._getSpinTarget(target), this._getSpinContainerTarget(target))
    })
  }

  _getSpinTarget(target) {
    return target.querySelector('.spin_component__spinner')
  }
  _getSpinContainerTarget(target) {
    return target.querySelector('.spin_component__container')
  }
}
