import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { confirmtext: String, action: String }

  connect() {
    super.connect()
    this.element.addEventListener('click', (e) => {
      if (!confirm(this.confirmtextValue)) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    })
  }
}
