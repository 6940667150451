import ApplicationController from './application_controller'
import consumer from '../channels/consumer'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = ['spin', 'container', 'error']

  initialize() {
    super.initialize()
    this.subscription = null
  }

  connect() {
    super.connect()
    useSpinner(this)
  }

  disconnect() {
    super.disconnect()

    this.unsubscribe()
    this.tearDownSpinner()
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.hasErrorTarget) this.errorTarget.remove()
    this.showSpinner()
    document.addEventListener(
      'turbo:before-stream-render',
      this.handleBeforeStreamRender.bind(this),
      { once: true }
    )
  }

  createSubscription(reportId) {
    this.currentReportId = reportId
    this.subscription = consumer.subscriptions.create(
      { channel: 'ReportChannel', organization_id: this.currentOrganizationId, id: reportId },
      {
        received: (data) => {
          this.hideSpinner()
          if (data.success && data.file) {
            window.location.href = data.file
          }
          this.unsubscribe()
        }
      }
    )
  }

  handleBeforeStreamRender(event) {
    event.preventDefault()
    const reportId = event.target.templateElement.innerHTML
    if (this.currentReportId !== reportId) this.createSubscription(reportId)
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
