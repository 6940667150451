import ApplicationController from './application_controller'
import { useSpinner } from './mixins'

export default class extends ApplicationController {
  static targets = [
    'dashboardFiltersForm',
    'baseTimePeriod',
    'compareTimePeriod',
    'baseDate',
    'compareDate',
    'spin',
    'container',
    'dashboardTopProductsForm',
    'dashboardTopSalesRepsForm',
    'dashboardTopCustomerGroupsForm',
    'dashboardTopCustomersForm',
    'dashboardTopWarehousesForm',
    'dashboardTopCategoriesForm'
   ]

  connect() {
    super.connect()
    useSpinner(this)
  }

  updateBaseTimePeriod() {
    switch (this.baseTimePeriodTarget.value) {
      case 'last_7_days':
        this.compareTimePeriodTarget.value = 'previous_7_days'
        break
      case 'last_4_weeks':
        this.compareTimePeriodTarget.value = 'previous_4_weeks'
        break
      case 'last_12_months':
        this.compareTimePeriodTarget.value = 'previous_12_months'
        break
      case 'month_to_date':
        this.compareTimePeriodTarget.value = 'previous_month'
        break
      case 'year_to_date':
        this.compareTimePeriodTarget.value = 'previous_year'
        break
    }

    this.submitForm()
  }

  updateCompareTimePeriod() {
    this.submitForm()
  }

  updateBaseDate() {
    if (this.baseDateTarget.value.includes('to')) {
      this.baseTimePeriodTarget.value = 'custom'
      this.submitForm()
    }
  }

  updateCompareDate() {
    if (this.compareDateTarget.value.includes('to')) {
      this.compareTimePeriodTarget.value = 'custom'
      this.submitForm()
    }
  }

  updateWarehouseIds() {
    this.submitForm()
  }

  updateBrandIds() {
    this.submitForm()
  }

  updateSalesRepresentativeUserIds() {
    this.submitForm()
  }

  updateUnits() {
    this.submitForm()
  }

  updateTopProductsList() {
    this.showSpinner()
    this.dashboardTopProductsFormTarget.requestSubmit()
  }

  updateTopSalesRepsList() {
    this.showSpinner()
    this.dashboardTopSalesRepsFormTarget.requestSubmit()
  }

  updateTopCustomerGroupsList() {
    this.showSpinner()
    this.dashboardTopCustomerGroupsFormTarget.requestSubmit()
  }

  updateTopCustomersList() {
    this.showSpinner()
    this.dashboardTopCustomersFormTarget.requestSubmit()
  }

  updateTopWarehousesList() {
    this.showSpinner()
    this.dashboardTopWarehousesFormTarget.requestSubmit()
  }

  updateTopCategoriesList() {
    this.showSpinner()
    this.dashboardTopCategoriesFormTarget.requestSubmit()
  }

  submitForm() {
    this.showSpinner()
    this.dashboardFiltersFormTarget.requestSubmit()
  }
}
