import ApplicationController from './application_controller'
import Manipulator from 'lib/dom/manipulator'
import { useMenu } from './mixins/useMenu'
import { usePopper } from './mixins/usePopper'
import DOM from 'lib/dom/dom'

export default class extends ApplicationController {
  static targets = ['overlay', 'trigger', 'menu', 'menuitem']

  connect() {
    super.connect()
    useMenu(this)
    usePopper(this)

    this.activeIndex = null
    this.show = false
    this.placement = Manipulator.getDataAttribute(this.element, 'placement') || 'bottom-start'
  }

  disconnect() {
    if (this.show) this._hideOverlay()
  }

  toggle() {
    if (!this.show) {
      this._showOverlay()
      this._focusOnList()
    } else {
      this._hideOverlay()
    }
  }

  onTABPress() {
    this._hideOverlay()
    this._setFocusToTrigger()
  }

  onESCPress() {
    this._hideOverlay()
    this._setFocusToTrigger()
  }

  onMenuItemClick() {
    this._hideOverlay()
    this._setFocusToTrigger()
  }

  popperOpened() {
    window.addEventListener('click', this.hide)
  }

  popperClosed() {
    window.removeEventListener('click', this.hide)
  }

  hide = (event) => {
    // skip if clicked element is the trigger or menu

    const controller = event.target.closest(`[data-controller="${this.identifier}"]`)

    if (this.element === controller) {
      return
    }

    if (this.show === true) this._hideOverlay()
  }

  _setFocusToTrigger() {
    this.triggerTarget.focus()
  }
}
